<template>
  <div>
    <Header />
    <ListaRecargas />
  </div>
</template>

<script>
import Header from "../components/Header";
import ListaRecargas from "../components/ListaRecargas"
export default {
  name: "Confirmacion",
  components: {
    Header,
    ListaRecargas
  }
};
</script>

<style>
</style>