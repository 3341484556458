<template>
  <div>
    <Header />
    <ProfesorDeuda />
  </div>
</template>

<script>
import Header from "../components/Header";
import ProfesorDeuda from "../components/ProfesorDeuda"

export default {
  name: "profesor",
  components: {
    Header,
    ProfesorDeuda
  }
};
</script>

<style>
</style>