<template>
  <div class="row mb-4">
    <div class="col-md-7 col-lg-7">
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Buscar Alumno</label>
        <div class="col-sm-7">
          <input
            v-on:keyup="buscarAlumno(inputAlumno)"
            v-model="inputAlumno"
            type="email"
            ref="inputdni"
            class="form-control"
            autocomplete="off"
            id="dni-input"
            placeholder="DNI o nombre y apellido..."
          />
          <div v-if="showAutocomplete">
            <ul class="autocomplete-ul">
              <li
                @click="searchAlumno(alum.dnialumno)"
                v-bind:key="index"
                v-for="(alum, index) in filteredAlumnos"
              >{{ alum.nombrealumno }} {{ alum.apepaternoalumno }} {{ alum.apematernoalumno }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">DNI</label>
        <div class="col-sm-7">
          <input v-model="alumnoMant.dnialumno" class="label-like-input" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Nombres</label>
        <div class="col-sm-7">
          <input v-model="alumnoMant.nombrealumno" class="label-like-input" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Apellido paterno</label>
        <div class="col-sm-7">
          <input v-model="alumnoMant.apepaternoalumno" class="label-like-input" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Apellido materno</label>
        <div class="col-sm-7">
          <input v-model="alumnoMant.apematernoalumno" class="label-like-input" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Correo</label>
        <div class="col-sm-7">
          <input v-model="alumnoMant.correoalumno" class="label-like-input" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Celular</label>
        <div class="col-sm-7">
          <input v-model="alumnoMant.celularalumno" class="label-like-input" />
        </div>
      </div>
    </div>
    <div class="col-md-5 col-lg-5">
      <div class="card">
        <div class="card-body saldo">
          <h1 class="card-title font-weight-bold">S/ {{alumnoMant.saldoalumno}}</h1>
          <p class="card-text">SALDO ACTUAL</p>
        </div>
      </div>
      <button
        v-bind:class="[(!alumnoMant.saldoalumno) ? 'disabled': '']"
        @click="actualizarAlumno()"
        class="btn btn-lg btn-primary btn-block mt-3"
        type="submit"
      >Actualizar datos</button>
      <button
        v-bind:class="[(!alumnoMant.saldoalumno) ? 'disabled': '']"
        @click="borrar()"
        class="btn btn-lg btn-primary btn-block mt-3"
        type="submit"
      >Borrar</button>
    </div>
    <table v-if="alumnoMant.listamovimientos" class="table table-striped mt-4">
      <thead>
        <tr>
          <th scope="col">FECHA</th>
          <th scope="col">HORA</th>
          <th scope="col">DETALLE</th>
          <th scope="col">MONTO</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(al, index) in alumnoMant.listamovimientos" :key="index">
          <td>{{al.fechatransaccion.substring(0,10)}}</td>
          <td>{{al.fechatransaccion.substring(11,16)}}</td>
          <td>{{al.detalle}}</td>
          <td v-bind:class="al.operacion == 'COMPRA' ? 'red-text' : 'green-text'">
            <span v-if="al.operacion == 'COMPRA'">-</span> S/ {{al.monto}}
          </td>
        </tr>
      </tbody> 
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: "AlumnoMantenimiento",
  data() {
    return {
      inputAlumno: "",
      showAutocomplete: false,
      filteredAlumnos: [],
      listabuscar: [],
      montoDeuda: "",
      disableConfirmar: true
    };
  },
  methods: {
    ...mapActions([
      "consultaAlumnoManteminieto",
      "clearAlumnoMant",
      "fetchAlumnos"
    ]),
    searchAlumno(dni) {
      this.inputAlumno = dni;
      this.showAutocomplete = false;
      this.consultaAlumnoManteminieto(dni);
    },
    buscarAlumno(dni) {
      let regex = /^\d+$/;

      console.log(regex.test(dni));

      if (dni.length == 8 && regex.test(dni)) {
        console.log("llamar servicio");
        this.consultaAlumnoManteminieto(dni);
      } else if (dni.length > 2) {
        console.log("entra autocomplete!");
        let objreq = {
          idcolegio: this.$route.params.idcole,
          criteriobusqueda: dni
        };
        let respBuscar = this.fetchAlumnos(objreq);
        respBuscar.then(responseAuto => {
          if (responseAuto.idrespuesta == 0) {
            this.showAutocomplete = true;
            this.listabuscar = responseAuto.listaalumnos;
            this.filteredAlumnos = this.listabuscar.filter(al => {
              return (
                al.nombrealumno.toLowerCase().startsWith(dni.toLowerCase()) ||
                al.apepaternoalumno.toLowerCase().startsWith(dni.toLowerCase())
              );
            });
          }
        });
      } else {
        this.showAutocomplete = false;
        this.clearAlumnoMant();
      }
    },
    borrar() {
      this.inputAlumno = "";
      this.clearAlumnoMant();
    },
    actualizarAlumno() {
      console.log(this.alumnoMant.dnialumno);
      let actAlumno = {
        tipoConsulta: "1",
        dniAntiguo: this.inputAlumno,
        alumno: {
          alunombre: this.alumnoMant.nombrealumno,
          alupaterno: this.alumnoMant.apepaternoalumno,
          alumaterno: this.alumnoMant.apematernoalumno,
          dni: this.alumnoMant.dnialumno,
          celular: this.alumnoMant.celularalumno,
          estado: "1",
          correo: this.alumnoMant.correoalumno
        }
      }

      console.log("Actualizar alumno: ", actAlumno)

      axios.post("https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/actualizaralumno", actAlumno, { headers: {
        'authorizationToken': 'allow' 
      }})
        .then(res => {
          console.log("Res ", res.data)
          if(res.data.idrespuesta == 0) {
            this.emitter.emit("success");
            this.borrar();
          }
        })
        .catch(e => {
          console.error(e);
        });
    }
  },
  computed: mapGetters(["alumnoMant"]),
  mounted() {
    // this.emitter.on("reset_dni", () => {
    //   this.inputAlumno = "";
    //   //document.getElementById("dni-input").focus();
    //   this.$refs.inputdni.focus();
    // });

    // this.emitter.on("invitado", () => {
    //   this.inputAlumno = "11111111";
    //   this.consultaAlumnoManteminieto(this.inputAlumno);
    // });
  }
};
</script>

<style>
h1.card-title {
  color: #fe8e2c;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #9c9c9c;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  pointer-events: none;
}

.red-text {
  color: #ff0012!important
}

.green-text {
  color: #00b32c!important
}

.btn-outline-primary {
  color: #0abdde;
  border-color: #0abdde;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #9c9c9c;
  background-color: transparent;
  border-color: #9c9c9c;
  pointer-events: none;
}

.autocomplete-ul {
  list-style-type: none;
  padding: 0;
  line-height: 25px;
  position: absolute;
  overflow: overlay;
  z-index: 99;
  border-radius: 3px;
  background: white;
  border: 1px solid #ced4da;
}
.autocomplete-ul li {
  padding: 5px 10px;
  cursor: pointer;
}

.autocomplete-ul li:hover {
  background: rgba(0, 0, 0, 0.08);
}

.autocomplete-ul li:not(:last-child) {
  border-bottom: 1px solid #ced4da;
}

#deuda-input {
  width: 70%;
  margin: 0 auto;
}

.label-like-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 0;
}
.saldo {
  line-height: 2;
}
.card-body {
  padding: 30px;
  text-align: center;
}

.modal-header {
  border-bottom: none;
}

.modal-body {
  text-align: center;
}

.modal-footer {
  border-top: none;
}

.btn-modal {
  min-width: 150px;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.75rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.75rem;
}

@media (max-width: 965px) {
  h1,
  .h1 {
    font-size: 2.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .saldo {
    line-height: 3.4;
  }

  .card-body.saldo {
    padding: 20px;
  }
}
</style>