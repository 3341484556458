<template>
  <div v-if="alumno" class="row mb-4">
    <div class="col-md-7 col-lg-7">
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Buscar Alumno</label>
        <div class="col-sm-7">
          <input
            v-on:keyup="buscarAlumno(inputAlumno)"
            v-model="inputAlumno"
            type="email"
            ref="inputdni"
            class="form-control"
            autocomplete="off"
            id="dni-input"
            placeholder="DNI o nombre y apellido..."
            autofocus
          />
          <div v-if="showAutocomplete">
            <ul class="autocomplete-ul">
              <li
                @click="searchAlumno(alum.dnialumno)"
                v-bind:key="index"
                v-for="(alum, index) in filteredAlumnos"
              >{{ alum.nombrealumno }} {{ alum.apepaternoalumno }} {{ alum.apematernoalumno }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Nombres</label>
        <div class="col-sm-7">
          <label class="label-like-input">{{alumno.nombrealumno}}</label>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Apellidos</label>
        <div class="col-sm-7">
          <label class="label-like-input">{{alumno.apepaternoalumno}} {{alumno.apematernoalumno}}</label>
        </div>
      </div>
    </div>
    <div class="col-md-5 col-lg-5">
      <div class="card">
        <div class="card-body saldo">
          <h1 class="card-title font-weight-bold">S/ {{alumno.saldoalumno}}</h1>
          <p class="card-text">SALDO ACTUAL</p>
        </div>
      </div>
      <button
        v-bind:class="[(alumno.idrespuesta != 0) ? 'disabled': '']"
        data-toggle="modal"
        data-target="#modalDeuda"
        class="btn btn-lg btn-primary btn-block mt-3"
        type="submit"
      >Recarga en efectivo</button>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modalDeuda"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalDeuda"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h1>Pago de deuda</h1>
            <p class="pt-4">Ingresar monto que desea cancelar</p>
            <input
              v-model="montoDeuda"
              class="form-control"
              id="deuda-input"
              autocomplete="off"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)"
              placeholder="Ingresar monto..."
            />
          </div>
          <div class="modal-footer mx-auto pb-5">
            <button
              type="button"
              class="btn btn-modal btn-outline-primary"
              data-dismiss="modal"
            >Cancelar</button>
            <button
              @click="pagarDeuda()"
              v-bind:class="[ disableConfirmar && montoDeuda.length > 0 ? '': 'disabled']"
              type="button"
              class="btn btn-modal btn-primary"
            >Confirmar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import JQuery from "jquery";
const $ = JQuery;
window.$ = $;

export default {
  name: "AlumnoInfo",
  data() {
    return {
      inputAlumno: "",
      showAutocomplete: false,
      filteredAlumnos: [],
      listabuscar: [],
      montoDeuda: "",
      disableConfirmar: true
    };
  },
  methods: {
    ...mapActions([
      "consultaAlumno",
      "clearAlumno",
      "fetchAlumnos",
      "clearAll"
    ]),
    searchAlumno(dni) {
      this.inputAlumno = dni;
      this.showAutocomplete = false;
      let idcole = this.$route.params.idcole;

      let req = {
        dnialumno: dni,
        idcolegio: this.$route.params.idcole
      };

      this.consultaAlumno(req);
    },
    getdatetime() {
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 < 10 ? "0" : "") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() < 10 ? "0" : "") +
        today.getDate();
      let time =
        today.getHours() +
        ":" +
        (today.getMinutes() < 10 ? "0" : "") +
        today.getMinutes() +
        ":" +
        (today.getSeconds() < 10 ? "0" : "") +
        today.getSeconds();
      let dateTime = date + " " + time;
      return dateTime;
    },
    pagarDeuda() {
      this.disableConfirmar = false;
      let fechaNow = this.getdatetime();

      let req = {
        dnialumno: this.inputAlumno,
        monto: this.montoDeuda,
        fechaCancelacion: fechaNow,
        tiporecarga: 2
      };

      console.log("Request", req);

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/cancelardeudaefectivo",
          req
        )
        .then(res => {
          console.log("Res ", res.data);
          if (res.data.idrespuesta == 0) {
            this.emitter.emit("success");
            this.montoDeuda = "";
            $("#modalDeuda").modal("hide");
            this.emitter.emit("reset_dni");
            this.clearAll();
          }
          this.disableConfirmar = true;
        })
        .catch(e => {
          console.error(e);
        });
    },
    buscarAlumno(dni) {
      let regex = /^\d+$/;

      console.log(regex.test(dni));

      if (dni.length == 8 && regex.test(dni)) {
        console.log("llamar servicio!!!");
        let req = {
          dnialumno: dni,
          idcolegio: this.$route.params.idcole
        };

        this.consultaAlumno(req);
      } else if (dni.length > 2) {
        console.log("entra autocomplete!");
        let objreq = {
          idcolegio: this.$route.params.idcole,
          criteriobusqueda: dni
        };
        let respBuscar = this.fetchAlumnos(objreq);
        respBuscar.then(responseAuto => {
          if (responseAuto.idrespuesta == 0) {
            this.showAutocomplete = true;
            this.listabuscar = responseAuto.listaalumnos;
            this.filteredAlumnos = this.listabuscar.filter(al => {
              return (
                al.nombrealumno.toLowerCase().startsWith(dni.toLowerCase()) ||
                al.apepaternoalumno.toLowerCase().startsWith(dni.toLowerCase())
              );
            });
          }
        });
      } else {
        this.showAutocomplete = false;
        this.clearAlumno();
      }
    }
  },
  computed: mapGetters(["alumno"]),
  mounted() {
    this.emitter.on("reset_dni", () => {
      console.log("reset todo")
      this.inputAlumno = "";
      $("#dni-input").focus();
      // document.getElementById("dni-input").focus();
      // this.$refs.inputdni.focus();
      // $("#dni-input").focus();
    });

    // this.emitter.on("invitado", () => {
    //   this.inputAlumno = "11111111";
    //   let req = {
    //     dnialumno: "11111111",
    //     idcolegio: this.$route.params.idcole
    //   };
    //   this.consultaAlumno(req);
    // });
  }
};
</script>

<style scoped>
h1.card-title {
  color: #fe8e2c;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #9c9c9c;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  pointer-events: none;
}

.btn-outline-primary {
  color: #0abdde;
  border-color: #0abdde;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #9c9c9c;
  background-color: transparent;
  border-color: #9c9c9c;
  pointer-events: none;
}

.autocomplete-ul {
  list-style-type: none;
  padding: 0;
  line-height: 25px;
  position: absolute;
  overflow: overlay;
  z-index: 99;
  border-radius: 3px;
  background: white;
  border: 1px solid #ced4da;
}
.autocomplete-ul li {
  padding: 5px 10px;
  cursor: pointer;
}

.autocomplete-ul li:hover {
  background: rgba(0, 0, 0, 0.08);
}

.autocomplete-ul li:not(:last-child) {
  border-bottom: 1px solid #ced4da;
}

#deuda-input {
  width: 70%;
  margin: 0 auto;
}

.label-like-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 0;
}
.saldo {
  line-height: 2;
}
.card-body {
  padding: 30px;
  text-align: center;
}

.modal-header {
  border-bottom: none;
}

.modal-body {
  text-align: center;
}

.modal-footer {
  border-top: none;
}

.btn-modal {
  min-width: 150px;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.75rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.75rem;
}

@media (max-width: 965px) {
  h1,
  .h1 {
    font-size: 2.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .saldo {
    line-height: 3.4;
  }

  .card-body.saldo {
    padding: 20px;
  }
}
</style>