<template>
  <div id="app">
    <Header />
    <Notificacion />
  </div>
</template>

<script>
import Header from '../components/Header'
import Notificacion from '../components/Notificacion.vue'

export default {
  name: 'Reporte',
  components: {
    Header,
    Notificacion
  }
}
</script>

<style>

</style>
