<template>
  <div>
    <div class="card card-body menu-container">
      <div class="card-deck">
        <div
          class="card card-outline"
          v-bind:key="index"
          v-for="(product, index) in products.listadeproductos"
        >
          <div class="card-body">
            <button
              class="btn-sm btn-outline-primary edit"
              v-if="product.edit == false"
              @click="editProduct(product, index)"
            >Editar</button>
            <button
              class="btn-sm btn-outline-primary edit"
              v-if="product.edit"
              @click="cancel(product, index)"
            >Cancelar</button>
            <button
              class="btn-sm btn-primary edit"
              v-if="product.edit"
              @click="makeEdit(product)"
            >Modificar</button>
            <button
              class="btn-sm btn-danger"
              v-if="product.edit"
              @click="showModal(product)"
            >Eliminar</button>
            <div class="input-row">
              <p>Nombre</p>
              <input
                v-model="product.nombreproducto"
                class="form-control nombre-producto"
                v-bind:class="product.edit ? '': 'disabled-input'"
              />
            </div>
            <div class="input-row">
              <p>Precio</p>
              <input
                v-model="product.montoproducto"
                class="form-control precio-producto"
                v-bind:class="product.edit ? '': 'disabled-input'"
              />
            </div>
            <div class="input-row">
              <p>Orden</p>
              <input
                v-model="product.ordenproducto"
                class="form-control precio-producto"
                v-bind:class="product.edit ? '': 'disabled-input'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalEliminar"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalEliminar"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h1>Eliminar Producto</h1>
            <p class="pt-4">¿Estas seguro que deseas eliminar el producto?</p>
          </div>
          <div class="modal-footer mx-auto pb-5">
            <button
              type="button"
              class="btn btn-modal btn-outline-primary"
              data-dismiss="modal"
            >Cancelar</button>
            <button
              @click="eliminarProducto()"
              type="button"
              class="btn btn-modal btn-primary"
              data-dismiss="modal"
            >Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: "ProductoModificar",
  data() {
    return {
      coleProductos: this.$route.params.idcole,
      showModificar: false,
      products: [],
      selectedProduct: []
    };
  },
  methods: {
    showModal(prod) {
      this.selectedProduct = prod;
      console.log("selectedProduct!", this.selectedProduct);
      $("#modalEliminar").modal("show");
    },
    eliminarProducto() {
      console.log("Eliminar!");
      console.log("Product selected eliminar: ", this.selectedProduct);

      let req = {
        idcolegio: this.$route.params.idcole,
        idproducto: this.selectedProduct.idproducto
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/eliminarproducto",
          req
        )
        .then(res => {

          console.log("Porducto eliminado ", res.data);
          this.getProducts();

        })
        .catch(e => {
          console.error(e);
        });
    },
    getProducts() {
      let req = {
        idcolegio: this.$route.params.idcole
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultaproducto",
          req
        )
        .then(res => {
          console.log("Resp", res.data);
          let tempList = res.data.listadeproductos.map(el => ({
            ...el,
            edit: false
          }));
          res.data.listadeproductos = tempList;
          this.products = res.data;
        })
        .catch(e => {
          console.error(e);
        });
    },
    makeEdit(prod) {
      console.log("make edit ", prod);

      let req = {
        idproducto: prod.idproducto,
        orden: prod.ordenproducto,
        precio: prod.montoproducto,
        nombreproducto: prod.nombreproducto,
        idcolegio: this.coleProductos
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/actualizarproducto",
          req
        )
        .then(res => {
          console.log("Resp Actualizar", res.data);
          this.getProducts();
        })
        .catch(e => {
          console.error(e);
        });
    },
    editProduct(prod, index) {
      this.products.listadeproductos.map(el => (el.edit = false));
      this.products.listadeproductos[index].edit = true;
    },
    cancel(prod, index) {
      this.products.listadeproductos[index].edit = false;
      this.getProducts();
    }
  },
  created() {
    this.getProducts();
  }
};
</script>

<style scoped>
h5,
.h5 {
  font-size: 1rem;
}

p {
  margin: 0;
}

.form-control {
  margin-top: 5px;
}

.input-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-row p {
  min-width: 30%;
}

.input-row input {
  max-width: 70%;
}

.edit {
  margin-right: 5px;
}

.disabled-input {
  pointer-events: none;
  background-color: lightgray;
}

.card.card-body.menu-container.div-disabled > div > div {
  background: #f2f2f2;
  pointer-events: none;
  color: #9c9c9c !important;
}

.card.card-body.menu-container.div-disabled > div > div > div > p {
  color: #9c9c9c !important;
}

.card.card-body.menu-container.div-disabled {
  background: #f2f2f2;
  pointer-events: none;
}

.menu-container {
  max-height: 600px;
  overflow: scroll;
}

.card-title {
  margin-bottom: 0.1rem;
}

.card-body {
  padding: 20px;
  text-align: center;
}

.card-deck {
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
.card-deck .card {
  margin-right: 5px;
  margin-left: 5px;
  min-height: 106px;
}

@media (max-width: 1080px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
}

@media (max-width: 965px) {
  .container {
    max-width: 95%;
  }

  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
  .card-deck .card {
    margin-right: 5px;
    margin-left: 5px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .card-body {
    padding: 15px;
  }
}

@media (max-width: 800px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
  }
  .card-deck .card {
    margin-right: 0px;
    margin-left: 0px;
  }
  .card-deck .card {
    margin-right: 15px;
    margin-left: 15px;
  }
}
</style>