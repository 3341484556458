<template>
  <div>
    <Header />
    <div class="container">
      <div class="row">
        <h3>Mantenimiento de Colegios</h3>
      </div>
      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Nombre:</label>
        <div class="col-sm-10">
          <input v-on:keyup="checkform()" autocomplete="off" v-model="nombreColegio" class="form-control form-control-lg"
            id="nombre" placeholder="nombre del colegio. . ." />
        </div>
      </div>
      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Dirección:</label>
        <div class="col-sm-10">
          <input v-on:keyup="checkform()" autocomplete="off" v-model="direccionColegio"
            class="form-control form-control-lg" id="direccion" placeholder="direccion del colegio. . ." />
        </div>
      </div>
      <div class="row">
        <button @click="registrarColegio()" class="btn btn-reporte btn-lg btn-primary mt-3 mb-2" v-bind:class="{ 'disabled': disabled }">
          <img v-if="showLoaderCole" class="loader" src="../assets/img/loader.gif" />
          Registrar colegio
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import axios from "axios";

export default {
  name: "Colegio",
  components: {
    Header
  },
  data() {
    return {
      disabled: true,
      showLoaderCole: false,
      nombreColegio: '',
      direccionColegio: ''
    }
  },
  methods: {
    registrarColegio() {
      this.showLoaderCole = true
      this.disabled = true

      let req = {
        tipoAdmin: "3",
        colegio: {
          nombre: this.nombreColegio,
          estado: 1,
          direccion: this.direccionColegio,
          flagcolegio: 1,
          flaganticipado: 0
        }
      }

      console.log("Req Mant Cole: ", req)

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/registraralumnoproductocolegio",
          req
        )
        .then(res => {
          console.log("res Mant Cole", res.data);
          if (res.data.idrespuesta == 0) {
            this.emitter.emit("success");
          } else {
            this.emitter.emit("error");
          }
          this.showLoaderCole = false;
          this.nombreColegio = "";
          this.direccionColegio = "";
        })
        .catch(err => console.log(err));



    },
    checkform() {
      if (document.getElementById("nombre").value != "" && document.getElementById("direccion").value != "") {
        console.log("todo bien!");
        this.disabled = false;
      } else {
        this.disabled = true;
        console.log("NO todo bien!");
      }
    }
  }
};
</script>

<style scoped>
h3 {
  margin-bottom: 1.7rem;
}

.loader {
  height: 30px;
}

::placeholder {
  color: #6c757d96 !important;
  font-size: 1rem;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #9c9c9c;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  pointer-events: none;
}

.col-form-label {
  color: #6c757d;
  padding-left: 0px;
  font-size: 1.1rem;
}

.form-control-lg {
  border-color: #6c757d;
}
</style>