<template>
  <div>
    <div class="row">
      <h3>Mantenimiento de Alumnos</h3>
    </div>
    <div class="form-group row">
      <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">DNI:</label>
      <div class="col-sm-10">
        <input
          v-on:keyup="checkform()"
          v-model="dniAlumno"
          autocomplete="off"
          class="form-control form-control-lg"
          id="dni"
          placeholder="dni del alumno. . ."
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Nombre:</label>
      <div class="col-sm-10">
        <input
          v-model="nombreAlumno"
          v-on:keyup="checkform()"
          autocomplete="nope"
          class="form-control form-control-lg"
          id="nombre"
          placeholder="nombre del alumno. . ."
        />
      </div>
    </div>
    <div class="form-group row">
      <label
        for="colFormLabelLg"
        class="col-sm-2 col-form-label col-form-label-lg"
      >Apellido paterno:</label>
      <div class="col-sm-10">
        <input
          v-model="apellidopAlumno"
          v-on:keyup="checkform()"
          autocomplete="nope"
          class="form-control form-control-lg"
          id="apellidop"
          placeholder="apellido paterno del alumno. . ."
        />
      </div>
    </div>
    <div class="form-group row">
      <label
        for="colFormLabelLg"
        class="col-sm-2 col-form-label col-form-label-lg"
      >Apellido materno:</label>
      <div class="col-sm-10">
        <input
          v-model="apellidomAlumno"
          v-on:keyup="checkform()"
          autocomplete="nope"
          class="form-control form-control-lg"
          id="apellidom"
          placeholder="apellido materno del alumno. . ."
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Correo:</label>
      <div class="col-sm-10">
        <input
          v-model="correoAlumno"
          v-on:keyup="checkform()"
          autocomplete="nope"
          class="form-control form-control-lg"
          id="correoa"
          placeholder="correo del alumno. . ."
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Celular:</label>
      <div class="col-sm-10">
        <input
          v-model="celularAlumno"
          v-on:keyup="checkform()"
          autocomplete="nope"
          class="form-control form-control-lg"
          id="celulara"
          placeholder="celular del alumno. . ."
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Profesor:</label>
      <div class="col-sm-10">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <input v-model="flagProfesor" type="checkbox" />
          </div>
        </div>
      </div>
    </div>

    <div class="row border-bottom">
      <button
        @click="registrarAlumno()"
        class="btn btn-reporte btn-lg btn-primary mt-3 mb-2"
        v-bind:class="{'disabled':disabled}"
      >Registrar alumno</button>
    </div>
    <div class="row mt-5">
      <h3>Carga masiva alumnos</h3>
    </div>
    <div class="row">
      <div class="upload-btn-wrapper">
        <p class="filename">{{ filename }}</p>
        <button class="btn-lg btn-outline-primary btn-block">Seleccionar archivo</button>
        <input type="file" id="file" ref="file" name="myfile" v-on:change="handleFileUpload()" />
      </div>
    </div>
    <div class="row">
      <button
        @click="submitFile()"
        v-bind:class="[(file != '' && !disableButton) ? '': 'disabled']"
        class="btn btn-lg btn-primary mt-3 mb-5 mr-2"
        type="submit"
      >
        <img v-if="showLoader" height="30px" src="../assets/img/loader.gif" />
        Cargar alumnos
      </button>
      <button
        v-bind:class="[(file != '' && !disableButton) ? '': 'disabled']"
        class="btn btn-lg btn-primary mt-3 mb-5"
        @click="resetLoad()"
      >Borrar</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AlumnoNuevo",
  data() {
    return {
      disabled: true,
      filename: "",
      disableButton: false,
      showLoader: false,
      file: "",
      dniAlumno: "",
      nombreAlumno: "",
      apellidopAlumno: "",
      apellidomAlumno: "",
      correoAlumno: "",
      celularAlumno: "",
      flagProfesor: false
    };
  },
  methods: {
    checkform() {
      if (
        document.getElementById("dni").value != "" &&
        document.getElementById("nombre").value != "" &&
        document.getElementById("apellidop").value != "" &&
        document.getElementById("apellidom").value != "" &&
        document.getElementById("correoa").value != "" &&
        document.getElementById("celulara").value != ""
      ) {
        console.log("todo bien!");
        this.disabled = false;
      } else {
        this.disabled = true;
        console.log("NO todo bien!");
      }
    },
    registrarAlumno() {
      let flagProfe = this.flagProfesor ? "1" : "0";

      let reqObj = {
        tipoAdmin: 2,
        colegioId: this.$route.params.idcole,
        producto: {
          prodnombre: "",
          prodprecio: "",
          estado: "",
          tipo: "",
          igv: ""
        },
        alumno: {
          alunombre: this.nombreAlumno,
          alupaterno: this.apellidopAlumno,
          alumaterno: this.apellidomAlumno,
          dni: this.dniAlumno,
          celular: this.celularAlumno,
          saldo: 0,
          estado: "",
          grado: "",
          correo: this.correoAlumno,
          profesor: flagProfe
        },
        colegio: {
          nombre: "",
          estado: "",
          direccion: ""
        }
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/registraralumnoproductocolegio",
          reqObj
        )
        .then(res => {
          console.log("res registrar alumno", res.data);
          if (res.data.idrespuesta == 0) {
            this.emitter.emit("success");
            this.disabled = false;
            resetAlumno();
          } else {
            this.emitter.emit("error");
          }
          this.showLoader = false;
          this.disabled = true;
        })
        .catch(err => console.log(err));
    },
    resetAlumno() {
      this.nombreAlumno = "";
      this.apellidopAlumno = "";
      this.apellidomAlumno = "";
      this.dniAlumno = "";
      this.celularAlumno = "";
      this.correoAlumno = "";
      this.flagProfesor = false;
    },
    handleFileUpload() {
      let uploadedFile = this.$refs.file.files[0];
      this.filename = this.$refs.file.files[0].name;

      console.log("uploadedFile: ", uploadedFile);

      let reader = new FileReader();
      reader.onloadend = uploadedFile => {
        this.file = reader.result;
      };
      reader.readAsDataURL(uploadedFile);
    },
    resetLoad() {
      this.filename = "";
      this.file = "";
      document.getElementById("file").value = "";
    },
    submitFile() {
      this.disableButton = true;
      this.showLoader = true;

      var f = this.file.indexOf(",");
      var filesend = this.file.substring(f + 1);

      console.log("filesend ", filesend);

      let objrecarga = {
        colegio: this.$route.params.idcole,
        datos: filesend
      };

      console.log("objrecarga ", objrecarga);

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/registraralumnomasivo",
          objrecarga
        )
        .then(res => {
          console.log("res carga masiva", res.data);
          if (res.data.idrespuesta == 0) {
            this.emitter.emit("success");
          } else {
            this.emitter.emit("error");
          }
          this.showLoader = false;
        })
        .catch(err => console.log(err));
    }
  }
};
</script>

<style>
h3 {
  margin-bottom: 1.7rem;
}

::placeholder {
  color: #6c757d96 !important;
  font-size: 1rem;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #9c9c9c;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  pointer-events: none;
}

.col-form-label {
  color: #6c757d;
  padding-left: 0px;
  font-size: 1.1rem;
}

.input-group-prepend {
  height: 100%;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.form-control-lg {
  border-color: #6c757d;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  cursor: pointer !important;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
}

.btn-outline-primary {
  color: #0abdde;
  border-color: #0abdde;
}

.filename {
  color: #0abdde;
}


</style>