<template>
  <div class="col-md-4 col-lg-4">
    <button
      @click="consultaAlumno({dnialumno: '11111111', idcolegio: this.$route.params.idcole})"
      class="btn btn-lg btn-outline-primary btn-block"
      type="submit"
    >Pago como invitado</button>
    <button
      @click="clearOrder()"
      class="btn btn-lg btn-primary btn-block mt-3"
      type="submit"
    >Borrar todo</button>
    <div class="card mt-3" style="overflow: scroll;">
      <div
        class="d-flex order-row"
        v-bind:class="(index % 2 == 0) ? '': 'grey-background'"
        v-bind:key="index"
        v-for="(ord, index) in completeOrder"
      >
        <div @click="deleteItem(index)" class="p-2 btn-delete">&#10005;</div>
        <div class="p-2 mr-auto">{{ ord.nombreproducto }}</div>
        <div class="p-2">S/ {{ ord.montoproducto.toFixed(2) }}</div>
      </div>
      <div class="card-footer d-flex mt-auto">
        <div class="p-2 mr-auto">Total</div>
        <div class="p-2">S/ {{ total_price.toFixed(2) }}</div>
      </div>
    </div>
    <button v-bind:class="[(alumno.idrespuesta == '0' && completeOrder.length != 0 && alumno.dnialumno != 11111111) ? '': 'disabled']" class="btn btn-lg btn-primary btn-block mt-3" data-toggle="modal" data-target="#modalSaldo" type="submit">Pago con saldo</button>
    <button v-bind:class="[(alumno.idrespuesta == '0' && completeOrder.length != 0 && alumno.dnialumno == 11111111) ? '': 'disabled']" class="btn btn-lg btn-outline-primary btn-block mt-3" data-toggle="modal" data-target="#modalEfectivo" type="submit">Efectivo</button>
    <!-- Modal Slado -->
    <div class="modal fade" id="modalSaldo" tabindex="-1" role="dialog" aria-labelledby="modalSaldo" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <h1>Confirmar pago con saldo</h1>
              <p class="pt-4">Estas a punto de efectuar una compra por <strong>S/ {{ total_price.toFixed(2) }}</strong></p>
          </div>
          <div class="modal-footer mx-auto pb-5">
            <button type="button" class="btn btn-modal btn-outline-primary" data-dismiss="modal">Cancelar</button>
            <button @click="createOrder(2)" type="button" class="btn btn-modal btn-primary">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Efectivo -->
    <div class="modal fade" id="modalEfectivo" tabindex="-1" role="dialog" aria-labelledby="modalEfectivo" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <h1>Confirmar pago en efectivo</h1>
              <p class="pt-4">Estas a punto de efectuar una compra por <strong>S/ {{ total_price.toFixed(2) }}</strong></p>
          </div>
          <div class="modal-footer mx-auto pb-5">
            <button type="button" class="btn btn-modal btn-outline-primary" data-dismiss="modal">Cancelar</button>
            <button @click="createOrder(0)" type="button" class="btn btn-modal btn-primary">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import JQuery from 'jquery'
let $ = JQuery
window.$ = $;

export default {
  name: "Order",
  data(){
    return {
      coleOrden: this.$route.params.idcole
    }
  },
  methods: {
    ...mapActions(["deleteItem", "clearAll", "addCompleteOrder", "consultaAlumno"]),
    clearOrder() {
      this.emitter.emit("reset_dni");
      this.clearAll();
      this.emitter.emit("resetSearchBar");
      
    },
    pad(s) {
      while (s.length < 2) {s = "0" + s;}
      return s;
    },
    alert() {
      this.emitter.emit("success");
    },
    getdatetime() {
      let today = new Date();
      let date = today.getFullYear()+'-'+((today.getMonth()+1) < 10 ? '0':'')+(today.getMonth()+1)+'-'+((today.getDate()) < 10 ? '0':'')+today.getDate();
      let time = today.getHours() + ":" + ((today.getMinutes()) < 10 ? '0':'') +today.getMinutes() + ":" + ((today.getSeconds()) < 10 ? '0':'') +today.getSeconds();
      let dateTime = date+' '+time;
      return dateTime
    },
    createOrder(tipoCompra) {
        this.emitter.emit("resetSearchBar")

        let datetimenow = this.getdatetime()

        var currentUser = JSON.parse(localStorage.getItem("user"));

        const ordenObj = {
            "dnialumno": this.alumno.dnialumno,
            "montocompra": this.total_price,
            "tipocompra": tipoCompra,
            "detallecompra": this.completeOrder,
            "fechacompra": datetimenow,
            "idcolegio": this.coleOrden,
            "username": currentUser.username

        }
        console.log("Resquest: ", ordenObj)
        
        $('#modalEfectivo').modal('hide');
        $('#modalSaldo').modal('hide');
        
        let respOrden = this.addCompleteOrder(ordenObj)
        respOrden.then( value => {
          console.log("Respuesta orden final!! ", value)
          if(value.idrespuesta == 0){
            this.alert()
          }
        })
        this.clearOrder()
    }
  },
  computed: {
    ...mapGetters(["completeOrder", "alumno"]),
    total_price() {
      return this.completeOrder.reduce(function(sum, item) {
        return sum + item.montoproducto;
      }, 0);
    }
  },
  filters: {
    twoDecimals: function(value) {
      if (!value) return "00.00";
      return value.toFixed(2);
    }
  }
};
</script>

<style scoped>
.btn-outline-primary {
  color: #0abdde;
  border-color: #0abdde;
}
.grey-background {
  background: rgba(0, 0, 0, 0.07) !important;
}

.modal-header {
    border-bottom: none;
}

.modal-body {
    text-align: center;
}

.modal-footer {
    border-top: none;
}

.btn-modal {
    min-width: 150px;
}

.modal-footer > :not(:last-child) {
    margin-right: .75rem;
}

.modal-footer > :not(:first-child) {
    margin-left: .75rem;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.btn-primary {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #9c9c9c;
    background-color: #F2F2F2;
    border-color: #F2F2F2;
    pointer-events: none;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #9c9c9c;
    background-color: transparent;
    border-color: #9c9c9c;
    pointer-events: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.p-2.btn-delete {
  background: #0abdde;
  color: white;
  padding: 10px 15px !important;
  cursor: default;
}

.p-2.btn-delete:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.p-2.btn-delete:active {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.card-footer {
  padding: 0;
}

.card.mt-3 {
  height: 440px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
        margin: 1.75rem auto;
    }
}

</style>