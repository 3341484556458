<template>
  <div id="app">
    <Alert />
    <router-view></router-view>
    <!-- <notifications position="top center" /> -->
  </div>
</template>

<script>
import Alert from "./components/Alert.vue"
export default {
  name: "app",
  components: {
    Alert
  }
};
</script>

<style>
/* .vue-notification-wrapper {
  width: 450px !important;
}

.vue-notification {
  text-align: center !important;
}

.notification-title {
  font-size: 22px;
} */

</style>
