<template>
  <div class="container">
    <!-- REPORTE DE VENTAS -->
    <div class="row">
      <h3>Reporte de Ventas</h3>
    </div>
    <div class="row">
      <div class="dropdown form-label-group mr-2">
        <button class="btn btn-outline-secondary form-control" type="button" id="dropdownMenu2" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ coleVentas.nombrecolegio }}
          <img src="../assets/img/down_arrow_color.png" class="float-right img-dropdown" style="margin-top: 3%;"
            height="15px" alt />
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <button v-bind:key="colegiov.idcolegio" v-for="colegiov in colegiosVenta" v-on:click="selectSchoolv(colegiov)"
            class="dropdown-item" type="button">{{ colegiov.nombrecolegio }}</button>
        </div>
      </div>
      <div class="date-div mr-2">
        <div>
          <label class="mr-2">Desde:</label>
          <input v-on:change="checkformv()" id="desde" class="box-fecha mr-2" type="date" data-date-format="DD MMMM YYYY"
            name="fecha" />
        </div>
        <div>
          <label class="mr-2">Hasta:</label>
          <input v-on:change="checkformv()" id="hasta" class="box-fecha" type="date" data-date-format="DD MMMM YYYY"
            name="fecha" />
        </div>
      </div>
    </div>
    <div class="row border-bottom">
      <button class="btn btn-reporte btn-lg btn-primary mb-2" v-bind:class="{ 'disabled': disabledv }"
        @click="generarReporteVentas()">
        <img v-if="showLoaderv" height="30px" src="../assets/img/loader.gif" />
        Exportar reporte
      </button>
    </div>
    <!-- REPORTE DE VENTAS END -->


    

    <!-- REPORTE CULQU -->
    <div class="row mt-5">
      <h3>Reporte de Pagos Culqi</h3>
    </div>
    <div class="row">
      <div class="dropdown form-label-group mr-2">
        <button class="btn btn-outline-secondary form-control" type="button" id="dropdownMenu2" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ coleVentasCulqi.nombrecolegio }}
          <img src="../assets/img/down_arrow_color.png" class="float-right img-dropdown" style="margin-top: 3%;"
            height="15px" alt />
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <button v-bind:key="colegioc.idcolegio" v-for="colegioc in colegiosVenta" v-on:click="selectSchoolc(colegioc)"
            class="dropdown-item" type="button">{{ colegioc.nombrecolegio }}</button>
        </div>
      </div>
      <div class="date-div mr-2">
        <div>
          <label class="mr-2">Desde:</label>
          <input v-on:change="checkformc()" id="desdeC" class="box-fecha mr-2" type="date" data-date-format="DD MMMM YYYY"
            name="fecha" />
        </div>
        <div>
          <label class="mr-2">Hasta:</label>
          <input v-on:change="checkformc()" id="hastaC" class="box-fecha" type="date" data-date-format="DD MMMM YYYY"
            name="fecha" />
        </div>
      </div>
    </div>
    <div class="row border-bottom">
      <button class="btn btn-reporte btn-lg btn-primary mb-2" v-bind:class="{ 'disabled': disabledc }"
        @click="generarReporteVentasCulqi()">
        <img v-if="showLoaderc" height="30px" src="../assets/img/loader.gif" />
        Exportar reporte
      </button>
    </div>
    <!-- REPORTE CULQU END -->








    <!-- REPORTE DE PEDIDO ANTICIPADO -->
    <div class="row mt-5">
      <h3>Reporte de Pedido Anticipado</h3>
    </div>
    <div class="row">
      <div class="dropdown form-label-group mr-2">
        <button class="btn btn-outline-secondary form-control" type="button" id="dropdownMenu2" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ coleAnticipado.nombrecolegio }}
          <img src="../assets/img/down_arrow_color.png" class="float-right img-dropdown" style="margin-top: 3%;"
            height="15px" alt />
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <button v-bind:key="colegioa.idcolegio" v-for="colegioa in colegiosVenta" v-on:click="selectSchoola(colegioa)"
            class="dropdown-item" type="button">{{ colegioa.nombrecolegio }}</button>
        </div>
      </div>
      <div class="date-div mr-2">
        <div>
          <label class="mr-2">Desde:</label>
          <input v-on:change="checkforma()" id="desdeA" class="box-fecha mr-2" type="date" data-date-format="DD MMMM YYYY"
            name="fecha" />
        </div>
        <div>
          <label class="mr-2">Hasta:</label>
          <input v-on:change="checkforma()" id="hastaA" class="box-fecha" type="date" data-date-format="DD MMMM YYYY"
            name="fecha" />
        </div>
      </div>
    </div>
    <div class="row border-bottom">
      <button class="btn btn-reporte btn-lg btn-primary mb-2" v-bind:class="{ 'disabled': disableda }"
        @click="generarReporteAnticipado()">
        <img v-if="showLoadera" height="30px" src="../assets/img/loader.gif" />
        Exportar reporte
      </button>
    </div>
    <!-- REPORTE DE PEDIDOS ANTICIPADOS END -->
    <!-- REPORTE DE DEUDORES -->
    <div class="row mt-5">
      <h3>Reporte de Deudores</h3>
    </div>
    <div class="row">
      <div class="dropdown form-label-group mr-2">
        <button class="btn btn-outline-secondary form-control" type="button" id="dropdownMenu2" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ coleDeudores.nombrecolegio }}
          <img src="../assets/img/down_arrow_color.png" class="float-right img-dropdown" style="margin-top: 3%;"
            height="15px" alt />
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <button v-bind:key="colegiod.idcolegio" v-for="colegiod in colegiosDeudores"
            v-on:click="selectSchoold(colegiod)" class="dropdown-item" type="button">{{ colegiod.nombrecolegio }}</button>
        </div>
      </div>
    </div>
    <div class="row border-bottom">
      <button class="btn btn-reporte btn-lg btn-primary mb-2" v-bind:class="{ 'disabled': disabledd }"
        @click="generarReporteDeudas()">
        <img v-if="showLoaderd" height="30px" src="../assets/img/loader.gif" />
        Exportar reporte
      </button>
    </div>
    <!-- REPORTE DE DEUDORES END -->
    <!-- <div class="row mt-5">
      <h3>Reporte de Profesores</h3>
    </div>
    <div class="row">
      <div class="dropdown form-label-group mr-2">
        <button
          class="btn btn-outline-secondary form-control"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{coleProfesores.nombrecolegio}}
          <img
            src="../assets/img/down_arrow_color.png"
            class="float-right img-dropdown"
            style="margin-top: 2%;"
            height="15px"
            alt
          />
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <button
            v-bind:key="colegiop.idcolegio"
            v-for="colegiop in colegiosProfesores"
            v-on:click="selectSchoolp(colegiop)"
            class="dropdown-item"
            type="button"
          >{{colegiop.nombrecolegio}}</button>
        </div>
      </div>
            <div class="date-div mr-2">
        <div>
          <label class="mr-2">Desde:</label>
          <input
            v-on:change="checkformp()"
            id="desdep"
            class="box-fecha mr-2"
            type="date"
            data-date-format="DD MMMM YYYY"
            name="fecha"
          />
        </div>
        <div>
          <label class="mr-2">Hasta:</label>
          <input
            v-on:change="checkformp()"
            id="hastap"
            class="box-fecha"
            type="date"
            data-date-format="DD MMMM YYYY"
            name="fecha"
          />
        </div>
      </div>
    </div>
    <div class="row border-bottom">
      <button
        class="btn btn-reporte btn-lg btn-primary mb-2"
        v-bind:class="{'disabled':disabledp}"
        @click="generarReporteProfesores()"
      >
        <img v-if="showLoaderp" height="30px" src="../assets/img/loader.gif" />
        Exportar reporte
      </button>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import exportFromJSON from "export-from-json";
import axios from "axios";
import JQuery from "jquery";
import XLSX from "xlsx";
let $ = JQuery;

export default {
  name: "ListaReportes",
  data() {
    return {
      showLoadera: false,
      showLoaderc: false,
      showLoaderv: false,
      showLoaderd: false,
      showLoaderp: false,
      reporteAnticipado: [],
      reporteVentas: [],
      reporteVentasCulqi: [],
      reporteDeudores: [],
      reporteProfesores: [],
      disableda: true,
      disabledc: true,
      disabledv: true,
      disabledd: true,
      disabledp: true,
      colegiosVenta: [],
      colegiosVentaCulqi: [],
      colegiosDeudores: [],
      colegiosProfesores: [],
      coleAnticipado: {
        nombrecolegio: "Seleccionar colegio",
        idcolegio: null
      },
      coleVentas: {
        nombrecolegio: "Seleccionar colegio",
        idcolegio: null
      },
      coleVentasCulqi: {
        nombrecolegio: "Seleccionar colegio",
        idcolegio: null
      },
      coleDeudores: {
        nombrecolegio: "Seleccionar colegio",
        idcolegio: null
      },
      coleProfesores: {
        nombrecolegio: "Seleccionar colegio",
        idcolegio: null
      }
    };
  },
  methods: {
    selectSchoola(colegioa) {
      this.coleAnticipado = colegioa;
      console.log("cole ", this.coleAnticipado);
      this.checkforma();
    },
    selectSchoolv(colegiov) {
      this.coleVentas = colegiov;
      console.log("cole ", this.coleVentas);
      this.checkformv();
    },

    selectSchoolc(colegioc) {
      this.coleVentasCulqi = colegioc;
      console.log("cole ", this.coleVentasCulqi);
      this.checkformc();
    },


    selectSchoold(colegiod) {
      this.coleDeudores = colegiod;
      console.log("cole ", this.coleDeudores);
      this.checkformd();
    },
    selectSchoolp(colegiop) {
      this.coleProfesores = colegiop;
      console.log("cole ", this.coleProfesores);
      this.checkformp();
    },
    checkforma() {
      if (
        document.getElementById("desdeA").value != "" &&
        document.getElementById("hastaA").value != "" &&
        this.coleAnticipado.nombrecolegio != "Seleccionar colegio"
      ) {
        console.log("todo bien!");
        this.disableda = false;
      } else {
        this.disableda = true;
        console.log("NO todo bien!");
      }
    },
    checkformc() {
      if (
        document.getElementById("desdeC").value != "" &&
        document.getElementById("hastaC").value != "" &&
        this.coleVentasCulqi.nombrecolegio != "Seleccionar colegio"
      ) {
        console.log("todo bien!");
        this.disabledc = false;
      } else {
        this.disabledc = true;
        console.log("NO todo bien!");
      }
    },
    checkformv() {
      if (
        document.getElementById("desde").value != "" &&
        document.getElementById("hasta").value != "" &&
        this.coleVentas.nombrecolegio != "Seleccionar colegio"
      ) {
        console.log("todo bien!");
        this.disabledv = false;
      } else {
        this.disabledv = true;
        console.log("NO todo bien!");
      }
    },
    checkformd() {
      if (this.coleDeudores.nombrecolegio != "Seleccionar colegio") {
        console.log("todo bien!");
        this.disabledd = false;
      } else {
        this.disabledd = true;
        console.log("NO todo bien!");
      }
    },
    checkformp() {
      if (
        document.getElementById("desdep").value != "" &&
        document.getElementById("hastap").value != "" &&
        this.coleProfesores.nombrecolegio != "Seleccionar colegio") {
        console.log("todo bien!");
        this.disabledp = false;
      } else {
        this.disabledp = true;
        console.log("NO todo bien!");
      }
    },
    generarReporteAnticipado() {
      this.disableda = true;
      this.showLoadera = true;
      let desde = document.getElementById("desdeA").value;
      let hasta = document.getElementById("hastaA").value;
      let id = this.coleAnticipado.idcolegio;
      console.log("desde: ", desde);
      console.log("hasta: ", hasta);
      console.log("idcolegio: ", id);
      let req = {
        idcolegio: id,
        fechainicompra: desde + " 00:00:00",
        fechafincompra: hasta + " 23:59:59"
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/obtenerconsumoanticipado",
          req
        )
        .then(res => {
          this.showLoadera = false;
          console.log("response: ", res.data);
          this.reporteAnticipado = res.data.listaconsumos;
          this.coleAnticipado = {
            nombrecolegio: "Seleccionar colegio",
            idcolegio: null
          };
          document.getElementById("desdeA").value = "";
          document.getElementById("hastaA").value = "";
          const data = this.reporteAnticipado
          const fileName = "reporte-anticipado"
          const exportType = exportFromJSON.types.csv

          if (data) exportFromJSON({ data, fileName, exportType });
          // // export json to Worksheet of Excel
          // // only array possible
          // console.log("Rep:", JSON.parse(JSON.stringify(this.reporteVentas)))
          // var reporteWS = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(this.reporteVentas)));
          // // A workbook is the name given to an Excel file
          // var wb = XLSX.utils.book_new(); // make Workbook of Excel
          // // add Worksheet to Workbook
          // // Workbook contains one or more worksheets
          // XLSX.utils.book_append_sheet(wb, reporteWS, "reporte-ventas"); // sheetAName is name of Worksheet
          // // export Excel file
          // XLSX.writeFile(wb, "reporte-ventas.xlsx"); // name of the file is 'book.xlsx'
        })
        .catch(err => console.log(err));
    },
    generarReporteVentas() {
      this.disabledv = true;
      this.showLoaderv = true;
      let desde = document.getElementById("desde").value;
      let hasta = document.getElementById("hasta").value;
      let id = this.coleVentas.idcolegio;
      console.log("desde: ", desde);
      console.log("hasta: ", hasta);
      console.log("idcolegio: ", id);
      let req = {
        idcolegio: id,
        fechainicompra: desde + " 00:00:00",
        fechafincompra: hasta + " 23:59:59"
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultarventas",
          req
        )
        .then(res => {
          this.showLoaderv = false;
          console.log("response: ", res.data);
          this.reporteVentas = res.data.listaventas;
          this.coleVentas = {
            nombrecolegio: "Seleccionar colegio",
            idcolegio: null
          };
          document.getElementById("desde").value = "";
          document.getElementById("hasta").value = "";
          const data = this.reporteVentas
          const fileName = "reporte-ventas"
          const exportType = exportFromJSON.types.csv

          if (data) exportFromJSON({ data, fileName, exportType });
          // // export json to Worksheet of Excel
          // // only array possible
          // console.log("Rep:", JSON.parse(JSON.stringify(this.reporteVentas)))
          // var reporteWS = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(this.reporteVentas)));
          // // A workbook is the name given to an Excel file
          // var wb = XLSX.utils.book_new(); // make Workbook of Excel
          // // add Worksheet to Workbook
          // // Workbook contains one or more worksheets
          // XLSX.utils.book_append_sheet(wb, reporteWS, "reporte-ventas"); // sheetAName is name of Worksheet
          // // export Excel file
          // XLSX.writeFile(wb, "reporte-ventas.xlsx"); // name of the file is 'book.xlsx'
        })
        .catch(err => console.log(err));
    },

    generarReporteVentasCulqi() {
      this.disabledc = true;
      this.showLoaderc = true;
      let desde = document.getElementById("desdeC").value;
      let hasta = document.getElementById("hastaC").value;
      let id = this.coleVentasCulqi.idcolegio;
      console.log("desdeC: ", desde);
      console.log("hastaC: ", hasta);
      console.log("idcolegio: ", id);
      let req = {
        idcolegio: id,
        fechainicio: desde + " 00:00:00",
        fechafin: hasta + " 23:59:59"
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/obtenerrecargaculqi",
          req
        )
        .then(res => {
          this.showLoaderc = false;
          console.log("response: ", res.data);
          this.reporteVentasCulqi = res.data.listarecargas;
          this.coleVentasCulqi = {
            nombrecolegio: "Seleccionar colegio",
            idcolegio: null
          };
          document.getElementById("desdeC").value = "";
          document.getElementById("hastaC").value = "";
          const data = this.reporteVentasCulqi
          const fileName = "reporte-pago-culqi"
          const exportType = exportFromJSON.types.csv

          if (data) exportFromJSON({ data, fileName, exportType });
          // // export json to Worksheet of Excel
          // // only array possible
          // console.log("Rep:", JSON.parse(JSON.stringify(this.reporteVentas)))
          // var reporteWS = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(this.reporteVentas)));
          // // A workbook is the name given to an Excel file
          // var wb = XLSX.utils.book_new(); // make Workbook of Excel
          // // add Worksheet to Workbook
          // // Workbook contains one or more worksheets
          // XLSX.utils.book_append_sheet(wb, reporteWS, "reporte-ventas"); // sheetAName is name of Worksheet
          // // export Excel file
          // XLSX.writeFile(wb, "reporte-ventas.xlsx"); // name of the file is 'book.xlsx'
        })
        .catch(err => console.log(err));
    },


    generarReporteDeudas() {
      this.disabledd = true;
      this.showLoaderd = true;
      let id = this.coleDeudores.idcolegio;
      console.log("idcolegio: ", id);
      let req = {
        idcolegio: id
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultardeudores",
          req
        )
        .then(res => {
          this.showLoaderd = false;
          console.log("response: ", res.data);
          this.reporteDeudores = res.data.listadeudores;

          this.coleDeudores = {
            nombrecolegio: "Seleccionar colegio",
            idcolegio: null
          };

          const data = this.reporteDeudores
          const fileName = "reporte-deudores"
          const exportType = exportFromJSON.types.csv

          if (data) exportFromJSON({ data, fileName, exportType });

          // // export json to Worksheet of Excel
          // // only array possible
          // var reporteWS = XLSX.utils.json_to_sheet(this.reporteDeudores);
          // // A workbook is the name given to an Excel file
          // var wb = XLSX.utils.book_new(); // make Workbook of Excel
          // // add Worksheet to Workbook
          // // Workbook contains one or more worksheets
          // XLSX.utils.book_append_sheet(wb, reporteWS, "reporte-deudores"); // sheetAName is name of Worksheet
          // // export Excel file
          // XLSX.writeFile(wb, "reporte-deudores.xlsx"); // name of the file is 'book.xlsx'
        })
        .catch(err => console.log(err));
    },
    generarReporteProfesores() {
      this.disabledp = true;
      this.showLoaderp = true;
      let desdep = document.getElementById("desdep").value;
      let hastap = document.getElementById("hastap").value;
      let id = this.coleProfesores.idcolegio;
      console.log("idcolegio: ", id);
      let req = {
        idcolegio: id,
        fechainicompra: desdep + " 00:00:00",
        fechafincompra: hastap + " 23:59:59"
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultaconsumoprofesor",
          req
        )
        .then(res => {
          this.showLoaderp = false;
          console.log("response: ", res.data);
          this.reporteProfesores = res.data.listaconsumos;

          this.coleProfesores = {
            nombrecolegio: "Seleccionar colegio",
            idcolegio: null
          };
          document.getElementById("desdep").value = "";
          document.getElementById("hastap").value = "";
          // export json to Worksheet of Excel
          // only array possible
          var reporteWS = XLSX.utils.json_to_sheet(this.reporteProfesores);
          // A workbook is the name given to an Excel file
          var wb = XLSX.utils.book_new(); // make Workbook of Excel
          // add Worksheet to Workbook
          // Workbook contains one or more worksheets
          XLSX.utils.book_append_sheet(wb, reporteWS, "reporte-profesores"); // sheetAName is name of Worksheet
          // export Excel file
          XLSX.writeFile(wb, "reporte-profesores.xlsx"); // name of the file is 'book.xlsx'
        })
        .catch(err => console.log(err));
    }
  },
  created() {
    axios
      .get(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultacolegio"
      )
      .then(res => {
        console.log("res1: ", res.data);
        var currentUser = JSON.parse(localStorage.getItem("user"));
        console.log("LocalStorage ", currentUser)

        if (currentUser.user == 'admin') {
          res.data.listacolegio.push({
            nombrecolegio: "TODOS LOS COLEGIOS",
            idcolegio: 0
          });
          this.colegiosVenta = res.data.listacolegio;
          this.colegiosDeudores = res.data.listacolegio;
          this.colegiosProfesores = res.data.listacolegio;
          console.log("colegios1: ", this.colegiosVenta);
          console.log("colegios2: ", this.colegiosDeudores);
          console.log("colegios3: ", this.colegiosProfesores);
        } else {
          this.colegiosVenta = res.data.listacolegio.filter((colegio) => currentUser.colegio == colegio.idcolegio);
          this.colegiosDeudores = res.data.listacolegio.filter((colegio) => currentUser.colegio == colegio.idcolegio);
          this.colegiosProfesores = res.data.listacolegio.filter((colegio) => currentUser.colegio == colegio.idcolegio);
          console.log("colegios1: ", this.colegiosVenta);
          console.log("colegios2: ", this.colegiosDeudores);
          console.log("colegios3: ", this.colegiosProfesores);
        }

      })
      .catch(err => console.log(err));
  }
};
</script>

<style scoped>
label {
  color: #6c757d;
}

.date-div {
  display: inline-flex;
  margin-bottom: 1rem;
}

h3 {
  margin-bottom: 1rem;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.btn-reporte {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.box-fecha {
  padding-right: 10px;
  border-radius: 0.25rem;
  line-height: 45px;
  border: 1px solid #6c757d;
  text-align: center;
  color: #6c757d;
}

.dropdown {
  width: fit-content;
}

.img-dropdown {
  margin-left: 15px;
  height: 15px;
}

.btn-outline-primary {
  color: #0abdde;
  border-color: #0abdde;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.btn-primary {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #9c9c9c;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  pointer-events: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #9c9c9c;
  background-color: transparent;
  border-color: #9c9c9c;
  pointer-events: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}
</style>