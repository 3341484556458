import axios from "axios";

import { createStore } from "vuex";

const store = createStore({
  state: {
    alumnoInfo: [],
    alumnoMantenimiento: [],
    productInfo: [],
    alumnoOrder: [],
    ordersPosted: [],
    recargasResponse: [],
  },
  
  getters: {
    alumno: (state) => state.alumnoInfo,
    alumnoMant: (state) => state.alumnoMantenimiento,
    products: (state) => state.productInfo,
    completeOrder: (state) => state.alumnoOrder,
    postedOrders: (state) => state.ordersPosted,
    recargas: (state) => state.recargasResponse,
  },
  
  actions: {
    async consultarRecargas({ commit }, recarga) {
      const response = await axios.post(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultarecarga",
        recarga
      );
      // console.log('Dni: ', dni)
      console.log("Response: ", response.data);
      commit("setRecargas", response.data);
      return response.data;
    },
    async confirmarRecarga({ commit }, id) {
      const response = await axios.post(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/confirmarrecarga",
        { idrecarga: id }
      );
      // console.log('Dni: ', dni)
      console.log("Response: ", response.data);
      commit("filterRecarga", id);
      return response.data;
    },
    async cancelarRecarga({ commit }, id) {
      const response = await axios.post(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/cancelarrecarga",
        { idrecarga: id }
      );
      // console.log('Dni: ', dni)
      console.log("Response: ", response.data);
      commit("filterRecarga", id);
      return response.data;
    },
    async addCompleteOrder({ commit }, ordenObj) {
      console.log("Ingresando Ordern");
      const response = await axios.post(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/ingresarcompra",
        ordenObj
      );
      console.log("Ordern Ingresada ", response.data);
      return response.data;
    },
  
    async cancelOrd({ commit }, req) {
      console.log("req: ", req);
      const response = await axios.post(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/anularcompra",
        req
      );
      console.log("Cancel Order ", response.data);
      commit("refreshOrders", req.idcompra);
    },
  
    async fetchOrders({ commit }, req) {
      console.log("req: ", req);
      const response = await axios.post(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultarcompras",
        req
      );
      console.log("Fetch Orders ", response.data);
      commit("showOrders", response.data);
    },
  
    async fetchAlumnos({ commit }, req) {
      console.log("req: ", req);
      const response = await axios.post(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/buscaralumno",
        req
      );
      console.log("Response Autocomplete ", response.data);
      return response.data;
    },
    async consultaAlumno({ commit }, req) {
      console.log("DNI idcole", req);
  
      const response = await axios.post(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultaalumno",
        { dnialumno: req.dnialumno, idcolegio: req.idcolegio }
      );
      // console.log('Dni: ', dni)
      console.log("Response: ", response.data);
      commit("setAlumno", response.data);
      return response.data;
    },
    async consultaAlumnoManteminieto({ commit }, dni) {
      const response = await axios.post(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultarmovimientos",
        { dnialumno: dni }
      );
      // console.log('Dni: ', dni)
      console.log("Response: ", response.data);
      commit("setAlumnoMantenimiento", response.data);
      return response.data;
    },
    async clearAlumno({ commit }) {
      // console.log("clear alumno")
      commit("clearAlumno");
    },
    async clearAlumnoMant({ commit }) {
      // console.log("clear alumno")
      commit("clearAlumnoMant");
    },
    async fetchProducts({ commit }, colegio) {
      console.log("idcolegio: ", colegio);
      const response = await axios.post(
        "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultaproducto",
        { idcolegio: colegio }
      );
      console.log("Response productos: ", response.data);
      commit("setProducts", response.data);
      return response.data;
    },
    async addOrder({ commit }, order) {
      // console.log('Response: ', response.data)
      commit("addOrd", order);
    },
    async deleteItem({ commit }, index) {
      // console.log('Response: ', response.data)
      commit("deleteRow", index);
    },
    async clearAll({ commit }) {
      // console.log("clear alumno")
      document.getElementById("dni-input").value = "";
      commit("clearOrder");
      commit("clearAlumno");
    }
  },
  
  mutations: {
    setAlumno: (state, res) => (state.alumnoInfo = res),
    setAlumnoMantenimiento: (state, res) => (state.alumnoMantenimiento = res),
    setProducts: (state, res) => {
      let newList = res.listadeproductos.map((el) => ({ ...el, edit: false }));
      res.listacompras = newList;
      state.productInfo = res;
      console.log("Rest add: ", res);
    },
    clearAlumno: (state) => (state.alumnoInfo = []),
    clearAlumnoMant: (state) => (state.alumnoMantenimiento = []),
    clearOrder: (state) => (state.alumnoOrder = []),
    addOrd: (state, order) => state.alumnoOrder.push(order),
    deleteRow: (state, index) => state.alumnoOrder.splice(index, 1),
    showOrders: (state, res) => (state.ordersPosted = res.listacompras),
    refreshOrders: (state, id) =>
      (state.ordersPosted = state.ordersPosted.filter(
        (ord) => ord.idcompra != id
      )),
    setRecargas: (state, res) => (state.recargasResponse = res),
    filterRecarga: (state, id) =>
      (state.recargasResponse.listarecargas = state.recargasResponse.listarecargas.filter(
        (rec) => rec.idrecarga !== id
      )),
  }
})

export default store;
