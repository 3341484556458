<template>
  <div id="app">
    <Header />
    <ListaReportes />
  </div>
</template>

<script>
import Header from '../components/Header'
import ListaReportes from '../components/ListaReportes'

export default {
  name: 'Reporte',
  components: {
    Header,
    ListaReportes
  }
}
</script>

<style>

</style>
