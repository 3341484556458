<template>
  <div class="container">
    <div class="date-div mr-2" v-if="userRole.user == 'admin'" >
        <div>
          <label class="mr-2">Desde:</label>
          <input
            id="desde"
            v-on:change="checkformv()"
            class="box-fecha mr-2"
            type="date"
            data-date-format="DD MMMM YYYY"
            name="fecha"
          />
        </div>
        <div class="mr-2">
          <label class="mr-2">Hasta:</label>
          <input
            id="hasta"
            v-on:change="checkformv()"
            class="box-fecha"
            type="date"
            data-date-format="DD MMMM YYYY"
            name="fecha"
          />
        </div>
        <button
        class="btn btn-reporte btn-lg btn-primary mb-2"
        v-bind:class="{'disabled':disabled}"
        @click="buscarOrdenes()"
      >
        Buscar
      </button>
      </div>
    <div class="row no-gutter auto">
      <div class="table-responsive-lg">
        <h2>
          <img
            @click="refreshOrders()"
            class="recargar-lista mr-2"
            src="../assets/img/recargar.png"
            width="30px"
            alt
          /> Anular Ordenes
        </h2>
        <table class="table table-striped table-borderless">
          <thead>
            <tr>
              <td scope="col">FECHA</td>
              <td scope="col">HORA</td>
              <td scope="col">MONTO</td>
              <td scope="col">DNI</td>
              <td scope="col">NONBRES Y APELLIDOS</td>
              <td scope="col">ACCIÓN</td>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(order, index) in postedOrders">
              <td>{{ order.fechacompra.substring(0,10) }}</td>
              <td>{{ order.fechacompra.substring(11,16) }}</td>
              <td scope="col">S/ {{ order.montocompra }}</td>
              <td scope="col">{{ order.dnialumno }}</td>
              <td scope="col">{{ order.nombrealumno }}</td>
              <td class="button-td">
                <button
                  @click="setCurrOrder(order.idcompra, order.tipocompra)"
                  class="btn btn-lg btn-primary mr-2"
                  data-toggle="modal"
                  data-target="#modalCancelar"
                  type="submit"
                >Anular orden</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal Aceptar -->
    <div
      class="modal fade"
      id="modalCancelar"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalCancelar"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h1>Anular orden</h1>
            <p class="pt-4">
              ¿Estas seguro que deseas
              <strong>anular</strong> la orden seleccionada?
            </p>
          </div>
          <div class="modal-footer mx-auto pb-5">
            <button
              type="button"
              class="btn btn-modal btn-outline-primary"
              data-dismiss="modal"
            >Cancelar</button>
            <button @click="anularOrden()" type="button" class="btn btn-modal btn-primary">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: "CancelarOrden",
  data() {
    return {
      cancelorder: [],
      currOrder: 0,
      currTipoCompra: '',
      disabled: true,
      userRole: ''
    };
  },
  methods: {
    ...mapActions(["fetchOrders", "cancelOrd"]),
    setCurrOrder(id, tipo) {
      console.log("current order ", id);
      console.log("current tipocompra ", tipo);
      this.currTipoCompra = tipo
      this.currOrder = id;
    },
    checkformv() {
      if (
        document.getElementById("desde").value != "" &&
        document.getElementById("hasta").value != ""
      ) {
        console.log("todo bien!");
        this.disabled = false;
      } else {
        this.disabled = true;
        console.log("NO todo bien!");
      }
    },
    buscarOrdenes(){
      let desde = document.getElementById("desde").value;
      let hasta = document.getElementById("hasta").value;

      let req = {
        idcolegio: this.$route.params.idcole,
        fechainicompra: desde + " 00:00:00",
        fechafincompra: hasta + " 23:59:59",
        indicador: 1
      };

      this.fetchOrders(req);

    },
    refreshOrders() {
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 < 10 ? "0" : "") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() < 10 ? "0" : "") +
        today.getDate();

      console.log("fecha actual", date);
      console.log("$route ", this.$route);

      let req = {
        idcolegio: this.$route.params.idcole,
        tipocompra: 2,
        limite: 10,
        fechaActual: date,
        indicador: 0
      };
      this.fetchOrders(req);
    },
    anularOrden() {
      console.log("anular orden: ", this.currOrder);
      let reqCancel = {
        idcompra: this.currOrder,
        tipocompra: this.currTipoCompra
      };
      let resCancel = this.cancelOrd(reqCancel);

      resCancel.then(value => {
        if (value.idrespuesta == 0) {
          this.emitter.emit("success");
        }
      });

      $("#modalCancelar").modal("hide");
    }
  },
  computed: mapGetters(["postedOrders"]),
  created() {

    this.userRole = JSON.parse(localStorage.getItem("user"));
    
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10 ? "0" : "") +
      (today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" : "") +
      today.getDate();

    console.log("fecha actual", date);
    console.log("$route ", this.$route);

    let req = {
      idcolegio: this.$route.params.idcole,
      tipocompra: 2,
      limite: 10,
      fechaActual: date,
      indicador: 0
    };
    this.fetchOrders(req);
  }
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
}

.recargar-lista {
  cursor: pointer;
}

.recargar-lista:hover {
  opacity: 0.3;
}

.date-div {
  display: inline-flex;
  margin-bottom: 1rem;
}

.box-fecha {
  padding-right: 10px;
  border-radius: 0.25rem;
  line-height: 45px;
  border: 1px solid #6c757d;
  text-align: center;
  color: #6c757d;
}

.recargar-lista:active {
  opacity: initial;
}

.btn-outline-primary {
  color: #0abdde;
  border-color: #0abdde;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0abdde;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #9c9c9c;
  background-color: transparent;
  border-color: #9c9c9c;
  pointer-events: none;
}

.modal-footer {
  border-top: none;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.75rem;
}

.modal-header {
  border-bottom: none;
}

.btn-modal {
  min-width: 150px;
}

.modal-body {
  text-align: center;
}
</style>