<template>
  <div>
    <div class="row">
      <h3>Mantenimiento de Productos</h3>
    </div>
    <div class="form-group row">
      <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Nombre:</label>
      <div class="col-sm-10">
        <input
          v-on:keyup="checkform()"
          autocomplete="off"
          v-model="nombreProducto"
          class="form-control form-control-lg"
          id="nombre"
          placeholder="nombre del producto. . ."
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Precio:</label>
      <div class="col-sm-10">
        <input
          v-on:keyup="checkform()"
          autocomplete="off"
          v-model="precioProducto"
          type="number"
          class="form-control form-control-lg"
          id="direccion"
          placeholder="precio del producto. . ."
        />
      </div>
    </div>
    <div class="row border-bottom">
      <button
        class="btn btn-reporte btn-lg btn-primary mt-3 mb-2"
        v-bind:class="{'disabled':disabled}"
        @click="registrarProducto()"
      >
      <img v-if="showLoaderProd" height="30px" src="../assets/img/loader.gif" />
      Registrar producto</button>
    </div>
    <div class="row mt-5">
      <h3>Carga masiva productos</h3>
    </div>
    <div class="row">
      <div class="upload-btn-wrapper">
        <p class="filename">{{ filename }}</p>
        <button class="btn-lg btn-outline-primary btn-block">Seleccionar archivo</button>
        <input type="file" id="file" ref="file" name="myfile" v-on:change="handleFileUpload()" />
      </div>
    </div>
    <div class="row">
      <button
        @click="submitFile()"
        v-bind:class="[(file != '' && !disableButton) ? '': 'disabled']"
        class="btn btn-lg btn-primary mt-3 mb-5 mr-2"
        type="submit"
      >
        <img v-if="showLoader" height="30px" src="../assets/img/loader.gif" />
        Cargar productos
      </button>
      <button
        v-bind:class="[(file != '' && !disableButton) ? '': 'disabled']"
        class="btn btn-lg btn-primary mt-3 mb-5"
        @click="resetLoad()"
      >Borrar</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProductoNuevoMasivo",
  data() {
    return {
      disabled: true,
      borrar: false,
      filename: "",
      disableButton: false,
      disableProdButton: false,
      showLoader: false,
      showLoaderProd: false,
      file: "",
      nombreProducto: "",
      precioProducto: ""
    };
  },
  methods: {
    checkform() {
      if (
        document.getElementById("nombre").value != "" &&
        document.getElementById("direccion").value != ""
      ) {
        console.log("todo bien!");
        this.disabled = false;
      } else {
        this.disabled = true;
        console.log("NO todo bien!");
      }
    },
    registrarProducto() {
      this.showLoaderProd = true;
      this.disabled = true;

      let req = {
        tipoAdmin: "1",
        colegioId: this.$route.params.idcole,
        producto: {
          prodnombre: this.nombreProducto,
          prodprecio: this.precioProducto,
          orden: 0,
          estado: "1",
          tipo: "1",
          igv: "18"
        },
        alumno: {
          alunombre: "",
          alupaterno: "",
          alumaterno: "",
          dni: "",
          celular: "",
          saldo: "",
          estado: "",
          grado: "",
          correo: "",
          profesor: ""
        },
        colegio: {
          nombre: "",
          estado: "",
          direccion: ""
        }
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/registraralumnoproductocolegio",
          req
        )
        .then(res => {
          console.log("res carga producto", res.data);
          if (res.data.idrespuesta == 0) {
            this.emitter.emit("success");
          } else {
            this.emitter.emit("error");
          }
          this.showLoaderProd = false;
          this.nombreProducto = "";
          this.precioProducto = "";
        })
        .catch(err => console.log(err));


    },
    handleFileUpload() {
      let uploadedFile = this.$refs.file.files[0];
      this.filename = this.$refs.file.files[0].name;

      let reader = new FileReader();
      reader.onloadend = uploadedFile => {
        this.file = reader.result;
      };
      reader.readAsDataURL(uploadedFile);
    },
    resetLoad() {
      this.filename = "";
      this.file = "";
      document.getElementById("file").value = "";
    },
    submitFile() {
      this.disableButton = true;
      this.showLoader = true;

      var f = this.file.indexOf(",");
      var filesend = this.file.substring(f + 1);

      console.log("filesend ", filesend);

      let objrecarga = {
        colegio: this.$route.params.idcole,
        datos: filesend
      };

      console.log("objrecarga ", objrecarga);

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/registrarproductomasivo",
          objrecarga
        )
        .then(res => {
          console.log("res carga masiva", res.data);
          if (res.data.idrespuesta == 0) {
            this.emitter.emit("success");
          } else {
            this.emitter.emit("error");
          }
          this.showLoader = false;
          document.getElementById("file").value = "";
        })
        .catch(err => console.log(err));
    }
  }
};
</script>

<style scoped>
::placeholder {
  color: #6c757d96 !important;
  font-size: 1rem;
}
</style>