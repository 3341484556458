<template>
  <div class="card card-body menu-container" v-bind:class="[(alumno.idrespuesta == '0') ? '' : 'div-disabled']">
    <input v-on:keyup="buscarProducto(inputBuscar)" v-model="inputBuscar" type="text" class="form-control buscarInput"
      autocomplete="off" placeholder="Buscar">
    <div class="card-deck">
      <div @click="addOrder(product)" class="card btn-outline-secondary" v-bind:key="orderProducts.idproducto"
        v-for="product in orderProducts.listadeproductos">
        <div class="card-body">
          <h5 class="card-title">{{ product.nombreproducto }}</h5>
          <p class="card-text">S/ {{ product.montoproducto }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import JQuery from "jquery";
const $ = JQuery;
window.$ = $;

export default {
  name: "Products",
  data() {
    return {
      coleProductos: this.$route.params.idcole,
      orderProducts: [],
      allProducts: [],
      inputBuscar: ''
    }
  },
  mounted() {
    // TODO replicar para error y para otros exitos
    this.emitter.on("resetSearchBar", () => {
      console.log("reset Search!");
      this.inputBuscar = ""
      this.orderProducts.listadeproductos = this.allProducts
    })
  },
  methods: {
    ...mapActions(["fetchProducts", "addOrder"]),
    buscarProducto(input) {
      console.log("input ", input)

      this.orderProducts.listadeproductos = this.allProducts.filter(product => product.nombreproducto.toUpperCase().includes(input.toUpperCase()))
    }
  },
  computed: mapGetters(["alumno", "products"]),
  async created() {
    let res =  await this.fetchProducts(this.coleProductos);
    this.orderProducts = res
    this.allProducts = res.listadeproductos
    console.log("orderProducts: ", this.orderProducts.listadeproductos)
  },
  filters: {
    twoDecimals: function (value) {
      if (!value) return "00.00";
      return value.toFixed(2);
    }
  }
};
</script>

<style scoped>
h5,
.h5 {
  font-size: 1.0rem;
}

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.card.card-body.menu-container.div-disabled>input {
  background: #F2F2F2;
  pointer-events: none;
  color: #9c9c9c !important;
}

.card.card-body.menu-container.div-disabled>div>div {
  background: #F2F2F2;
  pointer-events: none;
  color: #9c9c9c !important;
}

.card.card-body.menu-container.div-disabled>div>div>div>p {
  color: #9c9c9c !important;
}

.card.card-body.menu-container.div-disabled {
  background: #F2F2F2;
  pointer-events: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #9c9c9c;
  border-color: #9c9c9c;
}

.buscarInput {
  width: 400px;
  margin-left: -10px;
  margin-bottom: 16px;
  height: 45px;
}

.menu-container {
  max-height: 600px;
  overflow: scroll;
}

.card-title {
  margin-bottom: 0.1rem;
}

.card-body {
  padding: 20px;
  text-align: center;
}

.card-deck {
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.card-deck .card {
  margin-right: 5px;
  margin-left: 5px;
  min-height: 106px;
}

@media (max-width: 1080px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
}

@media (max-width: 965px) {

  .container {
    max-width: 95%;
  }

  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }

  .card-deck .card {
    margin-right: 5px;
    margin-left: 5px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .card-body {
    padding: 15px;
  }

}

@media (max-width: 800px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
  }

  .card-deck .card {
    margin-right: 0px;
    margin-left: 0px;
  }

  .card-deck .card {
    margin-right: 15px;
    margin-left: 15px;
  }
}
</style>