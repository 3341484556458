<template>
  <div>
    <Header />
    <div class="container">
      <div class="row">
        <h3>Mantenimiento de Usuarios</h3>
      </div>
      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Perfil:</label>
        <div class="col-sm-10">
          <div class="dropdown form-label-group">
            <button
              class="btn btn-outline-secondary form-control"
              type="button"
              id="perfil"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ selectedUser }}
              <img
                src="../assets/img/down_arrow_color.png"
                class="float-right img-dropdown"
                style="margin-top: 0.8%;"
                height="15px"
                alt
              />
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <button v-on:click="selectUser(2)" class="dropdown-item" type="button">Cajero</button>
              <button v-on:click="selectUser(3)" class="dropdown-item" type="button">Vendedor</button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Username:</label>
        <div class="col-sm-10">
          <input
            v-on:keyup="checkform()"
            autocomplete="off"
            class="form-control form-control-lg"
            id="username"
            placeholder="username. . ."
            v-model="userName"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="colFormLabelLg" class="col-sm-2 col-form-label col-form-label-lg">Password:</label>
        <div class="col-sm-10">
          <input
            type="password"
            v-on:keyup="checkform()"
            autocomplete="off"
            class="form-control form-control-lg"
            id="password"
            placeholder="password. . ."
            v-model="userPassword"
          />
        </div>
      </div>
      <div class="row">
        <button
          @click="registerUser()"
          class="btn btn-reporte btn-lg btn-primary mt-3 mb-2"
          v-bind:class="{'disabled':disabled}"
        >
          <img v-if="showLoader" height="30px" src="../assets/img/loader.gif" />
          Registrar usuario
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import axios from "axios";

export default {
  name: "Usuario",
  components: {
    Header
  },
  data() {
    return {
      disabled: true,
      selectedUser: "Seleccionar tipo de usuario. . .",
      selectedUserId: 0,
      userName: "",
      userPassword: "",
      showLoader: false
    };
  },
  methods: {
    checkform() {
      if (
        document.getElementById("username").value != "" &&
        document.getElementById("password").value != "" &&
        this.selectedUser != "Seleccionar tipo de usuario. . ."
      ) {
        console.log("todo bien!");
        this.disabled = false;
      } else {
        this.disabled = true;
        console.log("NO todo bien!");
      }
    },
    selectUser(usr) {
      this.selectedUserId = usr;
      this.selectedUser = usr == 2 ? "Cajero" : "Vendedor";
    },
    clearAll() {
      this.showLoader = false;
      this.selectedUser = "Seleccionar tipo de usuario. . .";
      this.selectedUserId = 0;
      this.userName = "";
      this.userPassword = "";
    },
    registerUser() {
      this.disabled = true;
      this.showLoader = true;
      console.log("Perfil: ", this.selectedUserId);
      console.log("Username: ", this.userName);
      console.log("Password: ", this.userPassword);
      console.log("idColegio: ", this.$route.params.idcole);
      let reqObj = {
        idusuario: this.userName,
        idcontrasena: this.userPassword,
        idcolegio: this.$route.params.idcole,
        idperfil: this.selectedUserId
      };

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/insertarusuario",
          reqObj
        )
        .then(res => {
          console.log("Res ", res.data);
          if (res.data.idrespuesta == 0) {
            this.emitter.emit("success");
            console.log("success");
            this.clearAll();
          } else if(res.data.idrespuesta == 2){
            this.emitter.emit("crear_usuario_repetido");
            this.clearAll();
          } else {
            this.emitter.emit("error");
            console.log("error");
            this.clearAll();
          }
        });
    }
  }
};
</script>

<style scoped>
.form-label-group {
  margin-bottom: 0px;
}

#perfil {
  text-align: left;
  padding-left: 16px;
}

.form-control-lg {
  font-size: 1.1rem;
  color: #6c757d;
}
</style>