import Vue, { createApp } from "vue";
import App from "./App.vue";
import "jquery-slim/dist/jquery.slim.js";
import 'jquery/dist/jquery.min.js';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { createRouter, createWebHistory } from "vue-router";
import Login from "./views/Login";
import Ventas from "./views/Ventas";
import Cancelacion from "./views/Cancelacion";
import Confirmacion from "./views/Confirmacion";
import Reporte from "./views/Reporte";
import Notificaciones from "./views/Notificaciones"
import Colegio from "./views/Colegio";
import Alumno from "./views/Alumno";
import Producto from "./views/Producto";
import Usuario from "./views/Usuario";
import Profesor from "./views/Profesor";
import store from './store'
import mitt from 'mitt';
import Notifications from '@kyvg/vue3-notification'

const emitter = mitt();

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Login },
    {
      path: "/ventas/:idcole",
      component: Ventas,
      meta: { authorize: ["admin", "cajero", "vendedor"] }  
    },
    {
      path: "/cancelacion/:idcole",
      component: Cancelacion,
      meta: { authorize: ["admin", "cajero"] }
    },
    {
      path: "/confirmacion/:idcole",
      component: Confirmacion,
      meta: { authorize: ["admin", "cajero"] }
    },
    {
      path: "/reporte/:idcole",
      component: Reporte,
      meta: { authorize: ["admin", "cajero", "vendedor"] }
    },
    {
      path: "/notificaciones/:idcole",
      component: Notificaciones,
      meta: { authorize: ["admin", "cajero"] }
    },
    {
      path: "/colegio/:idcole",
      component: Colegio,
      meta: { authorize: ["admin"] }
    },
    {
      path: "/alumno/:idcole",
      component: Alumno,
      meta: { authorize: ["admin", "cajero"] }
    },
    {
      path: "/producto/:idcole",
      component: Producto,
      meta: { authorize: ["admin", "cajero"] }
    },
    {
      path: "/usuario/:idcole",
      component: Usuario,
      meta: { authorize: ["admin"] }
    },
    {
      path: "/profesor/:idcole",
      component: Profesor,
      meta: { authorize: ["admin"] }
    }
  ]
});

router.beforeEach((to, from, next) => {
  var { authorize } = to.meta;
  var currentUser = JSON.parse(localStorage.getItem("user"));
  console.log("authorize", authorize);
  console.log("currentUser: ", currentUser);
  if (authorize) {
    if (localStorage.getItem("user") == null && to.path !== "/") {
      console.log("routed home!");
      next({ path: "/" });
    } else if (localStorage.getItem("user")) {
      if (to.path !== from.path) {
        let accessoRuta = JSON.parse(localStorage.getItem("user"));
        console.log("solo accesso ", accessoRuta);
        console.log("accessoRuta1 ", accessoRuta.colegio);
        console.log("to1", to);
        console.log("from1", from.path);
        if (authorize.length && !authorize.includes(currentUser.user)) {
          console.log("entra if");
          console.log("path", from.path);
          next("/");
        } else {
          
          console.log("Compare: ", to.path.split('/')[2])
          //if(accessoRuta.colegio != to.path.substr(to.path.length - 1)){
          if(accessoRuta.colegio != to.path.split('/')[2]){
            next("/");
          } else {
            console.log(to.path.substr(to.path.length - 1));
            console.log("entra else!");
            console.log("to2", to.path);
            console.log("from2", from.path);
            console.log("accessoRuta2", accessoRuta.colegio);
            next();
          }
        }
      }
    }
  } else {
    if (to.path !== from.path) {
      next();
    } else {
      next();
    }
  }
});

const app = createApp(App)
app.use(router)
app.use(store)
app.config.globalProperties.emitter = emitter;
app.use(Notifications)
app.mount('#app')
