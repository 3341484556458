<template>
  <div>
    <Header />
    <div class="container">
      <ul class="nav nav-tabs mb-2" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="home-tab"
            data-toggle="tab"
            href="#home"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >Agregar Productos</a>
        </li>
        <li v-if="userRole.user == 'admin'" class="nav-item">
          <a
            class="nav-link"
            id="profile-tab"
            data-toggle="tab"
            href="#profile"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >Editar Productos</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <ProductoNuevoMasivo />
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <ProductoModificar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import ProductoNuevoMasivo from "../components/ProductoNuevoMasivo";
import ProductoModificar from "../components/ProductoModificar"

export default {
  name: "Producto",
  components: {
    Header,
    ProductoNuevoMasivo,
    ProductoModificar
  },
  data() {
    return {
      userRole: ""
    };
  },
  created() {
    this.userRole = JSON.parse(localStorage.getItem("user"));
  }
};
</script>

<style scoped>
a.nav-link {
  font-size: 1.1rem;
}
</style>