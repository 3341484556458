<template>
  <div class="container">
    <div class="row">
      <h3>Notificacion de deudores</h3>
    </div>
    <button class="btn btn-reporte btn-lg btn-primary mb-2" v-bind:class="{ 'disabled': disabledn }"
      @click="enviarNotificacionDeudores()">
      <img v-if="showLoadern" src="../assets/img/loader.gif" />
      Enviar notificacion
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: "ListaReportes",
  data() {
    return {
      showLoadern: false,
      disabledn: false,
    };
  },
  methods: {
    enviarNotificacionDeudores() {
      console.log("Current Route Notificaciones: ", this.$route.params.idcole)
      this.disabledn = true
      this.showLoadern = true

      let req = {
        idcolegio: this.$route.params.idcole
      }

      axios
        .post("https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/enviarmaildeudor", req)
        .then(res => {
          console.log("Res Notificacion", res.data);
          if (res.data.idrespuesta == 0) {
            this.emitter.emit("success");
          } else {
            this.emitter.emit("success");
          }
          this.disabledn = false
          this.showLoadern = false
        })
        .catch(e => {
          console.error(e);
        });

    }
  }
};
</script>

<style scoped>
img {
  height: 30px;
}
</style>