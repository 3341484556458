<template>
  <div>
    <Header />
    <CancelarOrden />
  </div>
</template>

<script>
import Header from "../components/Header";
import CancelarOrden from "../components/CancelarOrden"

export default {
  name: "Cancelacion",
  components: {
    Header,
    CancelarOrden
  }
};
</script>

<style scoped>
a.nav-link {
  font-size: 1.1rem;
}
</style>>