<template>
  <div>
    <notifications position="top center" />
    <div
      class="alert hidden alert-success alert-dismissible fade show"
      id="alert-exito"
      role="alert"
    >
      <strong>¡Exito!</strong> La transaccion se proceso con exito.
      <button
        @click="closeAlert()"
        type="button"
        class="close"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
        <div
      class="alert hidden alert-danger alert-dismissible fade show"
      id="alert-error"
      role="alert"
    >
      <strong>¡Error!</strong> La transaccion no se proceso con exito.
      <button
        @click="closeAlert()"
        type="button"
        class="close"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import { mapGetters } from "vuex";
import JQuery from "jquery";
const $ = JQuery;
window.$ = $;

export default {
  name: "Alert",
  computed: mapGetters(["alumno"]),
  mounted() {
    // TODO replicar para error y para otros exitos
    this.emitter.on("success", () => {
      console.log("Success!");
      $("#alert-exito").show();
      setTimeout(function () {
        $("#alert-exito").slideUp();
        $("#dni-input").focus();
      }, 1500);
    });

    this.emitter.on("error", () => {
      console.log("Error!");
      $("#alert-error").show();
      setTimeout(function () {
        $("#alert-error").slideUp();
        $("#dni-input").focus();
      }, 1500);
    });
  },

  watch: {
    alumno(newValue, oldValue) {
      if (this.alumno.idrespuesta == 1) {
        notify({
          title: "¡La tansaccion no se proceso! ¡Error!",
          type: "error",
          duration: 2800,
        });
      }
    },
  },
};
</script>

<style>
.vue-notification-wrapper {
  width: 450px !important;
}

.vue-notification {
  text-align: center !important;
}

.notification-title {
  font-size: 22px;
}
.alert {
  position: absolute !important;
  z-index: 999;
  width: 100%;
  padding: 0.95rem 1.25rem;
}
.hidden {
  display: none;
}
</style>