<template>
   <div class="container">
    <div class="row no-gutter auto">
      <div class="table-responsive-lg">
        <img v-if="conrecargas == true" @click="recargarLista()" class="recargar-lista mr-2" src="../../src/assets/img/recargar.png" width="30px" alt="">
        <span v-if="conrecargas == true">Recargar lista</span>
        <label v-if="conrecargas == false"> No tienes recargas pendientes de aprobación</label>
        <table v-if="conrecargas == true" class="table table-striped table-borderless">
          <thead>
            <tr>
              <td scope="col">FECHA</td>
              <td scope="col">HORA</td>
              <td scope="col">MONTO</td>
              <td scope="col">IMAGEN</td>
              <td scope="col">NOMBRE Y APELLIDOS</td>
              <td scope="col">CONFIRMACIÓN</td>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(recarga, index) in recargas.listarecargas">
              <td>{{ recarga.fecharecarga.substring(0,10) }}</td>
              <td>{{ recarga.fecharecarga.substring(11,16) }}</td>
              <td>S/ {{ recarga.montorecarga }}</td>
              <td class="imgtd">
                  <img
                    @click="lightboxEffect(recarga.urlrecarga.slice(1, recarga.urlrecarga.length-1))"
                    src="../assets/img/voucher.png"
                    class="light-box__thumbnail">
              </td>
              <td>{{ recarga.nombrealumno }} {{ recarga.apellidos }}</td>
              <td class="button-td">
                <button @click="setId(recarga.idrecarga)" class="btn btn-lg btn-primary mr-2" data-toggle="modal" data-target="#modalAceptar" type="submit">Aceptar</button>
                <button @click="setId(recarga.idrecarga)" class="btn-lg btn-outline-primary" data-toggle="modal" data-target="#modalCancelar">Cancelar</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div @click.stop="bg = !bg" class="light-box__bg" v-if="bg"></div>
    </transition>
    <div v-if="bg">
      <div class="light-box__close" @click.stop="bg = !bg">X</div>
      <div v-if="bg" class="light-box__container">
        <transition name="fade" mode="out-in">
          <img
            :key="currentImage"
            :src=currentImage
            class="light-box__container__img"
          >
        </transition>
      </div>
    </div>
    <!-- Modal Aceptar -->
    <div class="modal fade" id="modalAceptar" tabindex="-1" role="dialog" aria-labelledby="modalAceptar" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <h1>Confirmar recarga</h1>
              <p class="pt-4">¿Estas seguro que deseas <strong>confirmar</strong> la recarga? </p>
          </div>
          <div class="modal-footer mx-auto pb-5">
            <button type="button" class="btn btn-modal btn-outline-primary" data-dismiss="modal">Cancelar</button>
            <button @click="confirmRecarga()" type="button" class="btn btn-modal btn-primary">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Cancelar -->
    <div class="modal fade" id="modalCancelar" tabindex="-1" role="dialog" aria-labelledby="modalCancelar" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <h1>Cancelar recarga</h1>
              <p class="pt-4">¿Estas seguro que deseas <strong>cancelar</strong> la recarga?</p>
          </div>
          <div class="modal-footer mx-auto pb-5">
            <button type="button" class="btn btn-modal btn-outline-primary" data-dismiss="modal">Cancelar</button>
            <button @click="confirmCancelar()" type="button" class="btn btn-modal btn-primary">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import JQuery from 'jquery'
let $ = JQuery

export default {
  name: "ListaRecargas",

  data() {
    return {
      bg: false,
      currentImage: '',
      count: true,
      recargaId: 0,
      conrecargas: 'temp',
    };
  },
  methods: {
    ...mapActions(["consultarRecargas", "confirmarRecarga", "cancelarRecarga"]),
    lightboxEffect(curr) {
      console.log("curr ", curr)
      this.currentImage = curr;
      this.bg = !this.bg;
    },
    recargarLista(){
      let requestRecarga = {
        idcolegio: this.$route.params.idcole,
        estadorecarga: 0
      }
      this.consultarRecargas(requestRecarga)

    },
    confirmRecarga() {
      let resultConfirm = this.confirmarRecarga(this.recargaId)
      resultConfirm.then( value => {
        if(value.idrespuesta == 0){
          this.emitter.emit("success");
        }
      })
      $('.modal').modal('hide');
    },
    setId(recId){
      this.recargaId = recId
    },
    confirmCancelar() {
      let resultCancel = this.cancelarRecarga(this.recargaId)
      resultCancel.then( value => {
        if(value.idrespuesta == 0){
          this.emitter.emit("success");
        }
      })
      $('.modal').modal('hide');
    }
  },
  computed: mapGetters(["recargas"]),
  created() {
    let requestRecarga = {
      idcolegio: this.$route.params.idcole,
      estadorecarga: 0
    }
    let respRecargas = this.consultarRecargas(requestRecarga);
    respRecargas.then( resp => {
      console.log("resp ", resp.listarecargas)
      if(resp.listarecargas.length == 0){
        this.conrecargas = false
      } else {
        this.conrecargas = true
      }
    })
  },
  filters: {
    twoDecimals: function(value) {
      if (!value) return "00.00";
      return value.toFixed(2);
    }
  }
}
</script>

<style scoped lang="scss">

.recargar-lista {
  cursor: pointer;
  width: 30px;
}

.recargar-lista:hover {
  opacity: 0.3;
}

.recargar-lista:active {
  opacity: initial;
}



.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.light-box {
  &__bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.59);
    z-index: 1000;
  }

  &__thumbnail {
    cursor: pointer;
  }

  &__close {
    padding: 10px;
    position: absolute;
    right: 20px;
    top: 20px;
    //background-image: url(/img/lightbox/close.svg);
    background-size: contain;
    background-position: center;
  }
  &__container {
    position: absolute;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 900px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    img {
      align-self: center;
      height: 100%
    }
  }
  &__btn {
    background-size: contain;
    background-position: center;
    align-self: center;
    padding: 15px;
  }

  &__close,
  &__btn {
    cursor: pointer;
  }

  &__close,
  &__btn {
    position: absolute;
    z-index: 3000;
  }
}

.btn-lg, .btn-group-lg > .btn {
    padding: 0.3rem 1rem;
    font-size: 1.1rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.imgtd {
  text-align: center;
  img {
    height: 40px;
  }
}

.button-td {
  display: flex;
}

.modal-footer {
  border-top: none;
}

.modal-footer > :not(:last-child) {
    margin-right: .75rem;
}

.modal-header {
  border-bottom: none;
}

.btn-modal {
    min-width: 150px;
}

.modal-body {
text-align: center;
}

.table th, .table td {
  padding: 0.75rem;
  vertical-align: middle;
}

.btn-outline-primary {
  color: #0abdde;
  border-color: #0abdde;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.btn-primary {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #9c9c9c;
    background-color: #F2F2F2;
    border-color: #F2F2F2;
    pointer-events: none;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #9c9c9c;
    background-color: transparent;
    border-color: #9c9c9c;
    pointer-events: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

@media (min-width: 576px) {
.modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
}
}

</style>