<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light mb-5">
    <label v-if="$route.params.idcole == 1" class="colegio-label col-form-label">
      <img class="pr-3" height="60px" src="../assets/img/jurgens-logo.png" />
      San Agustin
    </label>
    <label v-if="$route.params.idcole == 2" class="colegio-label col-form-label">
      <img class="pr-3" height="60px" src="../assets/img/jurgens-logo.png" />
      Santa Ursula
    </label>
    <label v-if="$route.params.idcole == 3" class="colegio-label col-form-label">
      <img class="pr-3" height="60px" src="../assets/img/jurgens-logo.png" />
      Abraham Lincoln
    </label>
    <!-- <a v-if="$route.params.idcole == 1" class="navbar-brand mr-auto" href="">San Agustin</a>
    <a v-if="$route.params.idcole == 2" class="navbar-brand mr-auto" href="">Santa Ursula</a>
    <a v-if="$route.params.idcole == 3" class="navbar-brand mr-auto" href="">Abraham Lincoln</a>-->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto"></ul>
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <div class="dropdown form-label-group">
            <button
              class="btn form-control"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Opciones
              <img class="image-drop" src="../assets/img/down_arrow_color.png" height="15px" alt />
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
              <button
                v-bind:class="[!(/^\/ventas\/((?:[^\/]+?))(?:\/(?=$))?$/i.test($route.fullPath)) ? '' : 'disabled']"
                @click="$router.push(`/ventas/${$route.params.idcole}`)"
                class="dropdown-item"
                type="button"
              >Ventas</button>
              <button
                v-bind:class="[!(/^\/cancelacion\/((?:[^\/]+?))(?:\/(?=$))?$/i.test($route.fullPath)) ? '' : 'disabled']"
                v-if="userRole.user == 'admin' || userRole.user == 'cajero'"
                @click="$router.push(`/cancelacion/${$route.params.idcole}`)"
                class="dropdown-item"
                type="button"
              >Anular orden</button>
              <button
                v-bind:class="[!(/^\/confirmacion\/((?:[^\/]+?))(?:\/(?=$))?$/i.test($route.fullPath)) ? '' : 'disabled']"
                v-if="userRole.user == 'admin' || userRole.user == 'cajero'"
                @click="$router.push(`/confirmacion/${$route.params.idcole}`)"
                class="dropdown-item"
                type="button"
              >Confirmar recarga</button>
              <button
                v-bind:class="[!(/^\/reporte\/((?:[^\/]+?))(?:\/(?=$))?$/i.test($route.fullPath)) ? '' : 'disabled']"
                @click="$router.push(`/reporte/${$route.params.idcole}`)"
                class="dropdown-item"
                type="button"
              >Reportes</button>
              <button
                v-bind:class="[!(/^\/notificaciones\/((?:[^\/]+?))(?:\/(?=$))?$/i.test($route.fullPath)) ? '' : 'disabled']"
                v-if="userRole.user == 'admin' || userRole.user == 'cajero'"
                @click="$router.push(`/notificaciones/${$route.params.idcole}`)"
                class="dropdown-item"
                type="button"
              >Notificaciones</button>
              <div
                v-if="userRole.user == 'admin' || userRole.user == 'cajero'"
                class="btn-group dropleft"
                onclick="return false"
              >
                <button
                  type="button"
                  class="btn btn-mantenimiento dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >Mantenimiento</button>
                <div class="dropdown-menu dropdown-mant">
                  <button
                    v-bind:class="[!(/^\/colegio\/((?:[^\/]+?))(?:\/(?=$))?$/i.test($route.fullPath)) ? '' : 'disabled']"
                    v-if="userRole.user == 'admin'"
                    @click="$router.push(`/colegio/${$route.params.idcole}`)"
                    class="dropdown-item"
                    type="button"
                  >Colegios</button>
                  <button
                    v-bind:class="[!(/^\/alumno\/((?:[^\/]+?))(?:\/(?=$))?$/i.test($route.fullPath)) ? '' : 'disabled']"
                    v-if="userRole.user == 'admin' || userRole.user == 'cajero'"
                    @click="$router.push(`/alumno/${$route.params.idcole}`)"
                    class="dropdown-item"
                    type="button"
                  >Alumnos</button>
                  <button
                    v-bind:class="[!(/^\/producto\/((?:[^\/]+?))(?:\/(?=$))?$/i.test($route.fullPath)) ? '' : 'disabled']"
                    v-if="userRole.user == 'admin' || userRole.user == 'cajero'"
                    @click="$router.push(`/producto/${$route.params.idcole}`)"
                    class="dropdown-item"
                    type="button"
                  >Productos</button>
                  <button
                    v-bind:class="[!(/^\/usuario\/((?:[^\/]+?))(?:\/(?=$))?$/i.test($route.fullPath)) ? '' : 'disabled']"
                    v-if="userRole.user == 'admin'"
                    @click="$router.push(`/usuario/${$route.params.idcole}`)"
                    class="dropdown-item"
                    type="button"
                  >Usuarios</button>
                  <button
                    v-bind:class="[!(/^\/profesor\/((?:[^\/]+?))(?:\/(?=$))?$/i.test($route.fullPath)) ? '' : 'disabled']"
                    v-if="userRole.user == 'admin'"
                    @click="$router.push(`/profesor/${$route.params.idcole}`)"
                    class="dropdown-item"
                    type="button"
                  >Profesores</button>
                </div>
              </div>
              <button @click="salir()" class="dropdown-item" type="button">Salir</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      showDropdown: false,
      userRole: ""
    };
  },
  methods: {
    salir() {
      localStorage.clear();
      this.$router.push("/");
    }
  },
  created() {
    this.userRole = JSON.parse(localStorage.getItem("user"));
  }
};
</script>

<style scoped>

.image-drop {
  height: 15px;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}
.navbar {
  padding: 0.5rem 5rem;
}
.btn-mantenimineto {
  padding: 0.25rem 0.6rem;
}

.dropleft .dropdown-menu {
  margin-right: 0rem;
}

.form-label-group {
    margin-bottom: 0rem;
}

.navbar-collapse{
  justify-content: end;
}

.dropleft:hover .dropdown-mant {
  display: block;
}

.dropleft .dropdown-toggle::before {
  color: #80808063;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d63;
  pointer-events: none;
  background-color: transparent;
}

.btn-mantenimineto:hover,
.btn-mantenimineto:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0abdde;
}
.colegio-label {
  font-size: 20px;
  font-weight: 600;
}
</style>>