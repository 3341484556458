<template>
  <div class="container">
    <h4>Eliminar la deuda de todo los profesores</h4>
    <button
      data-toggle="modal"
      data-target="#modalDeuda"
      class="btn btn-lg btn-primary mt-3"
      v-bind:class="{'disabled':disabled}"
      type="submit"
    >
      Ejecutar
      <img v-if="showLoader" class="ml-3" height="30px" src="../assets/img/loader.gif" />
    </button>
    <div
      class="modal fade"
      id="modalDeuda"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalDeuda"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h1>Eliminar deuda</h1>
            <p class="pt-4">¿Estas seguro que deseas eliminar la deuda de todos lo profesores?</p>
          </div>
          <div class="modal-footer mx-auto pb-5">
            <button
              type="button"
              class="btn btn-modal btn-outline-primary"
              data-dismiss="modal"
            >Cancelar</button>
            <button
              @click="eliminarDeuda()"
              type="button"
              class="btn btn-modal btn-primary"
            >Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: "profesorDeuda",
  data() {
    return {
      showLoader: false,
      disabled: false
    };
  },
  methods: {
    eliminarDeuda() {
      console.log("Eliminar deuda");
      $("#modalDeuda").modal("hide");
      this.showLoader = true;
      this.disabled = true;

      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 < 10 ? "0" : "") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() < 10 ? "0" : "") +
        today.getDate();

      let req = {
        idcolegio: this.$route.params.idcole,
        fecharecarga: date + " 00:00:00"
      };

      console.log("REQ ", req);

      axios
        .post(
          "https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/cancelardeudamasiva",
          req
        )
        .then(res => {
          if(res.data.idrespuesta == 0){
            this.emitter.emit("success");
            this.showLoader = false;
            this.disabled = false;
          } else if(res.data.idrespuesta == 0){
            console.log("No hay profesores con deuda");
            this.showLoader = false;
            this.disabled = false;
          }
        })
        .catch(err => console.log(err));
    }
  }
};
</script>

<style scoped>
.btn-primary.disabled,
.btn-primary:disabled {
  color: #9c9c9c;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  pointer-events: none;
}
</style>