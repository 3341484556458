<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row no-gutter">
        <div class="col-md-8 col-lg-6">
          <div class="login d-flex align-items-center py-5">
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-lg-7 mx-auto">
                  <h1 class="login-heading">¡Bienvenido!</h1>
                  <p>Para comenzar ingresa a tu cuenta</p>
                  <br />
                  <form>
                    <div class="dropdown form-label-group">
                      <button
                        class="btn btn-outline-secondary form-control"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{cole.nombrecolegio}}
                        <img
                          src="../assets/img/down_arrow_color.png"
                          class="float-right image-drop right"
                          style="margin-top: 2%;"
                          height="15px"
                          alt
                        />
                      </button>
                      <div class="dropdown-menu" aria-labelledby="  dropdownMenu2">
                        <!-- <button v-bind:key="colegio.id" v-for="colegio in colegios" v-on:click="selectSchool(colegio)" class="dropdown-item" type="button">{{colegio.nombre}}</button> -->
                        <button v-bind:key="colegio.idcolegio" v-for="colegio in colegios" v-on:click="selectSchool(colegio)" class="dropdown-item" type="button">{{colegio.nombrecolegio}}</button>
                      </div>
                    </div>
                    <div class="form-label-group">
                      <input 
                        v-on:keyup="checkform"
                        v-model="loginData.email"
                        id="inputEmail"
                        class="form-control"
                        placeholder="User"
                      />
                      <label for="inputEmail">User</label>
                    </div>
                    <div class="form-label-group">
                      <input 
                        v-on:keyup="checkform"
                        v-model="loginData.password"
                        type="password"
                        id="inputPassword"
                        class="form-control"
                        placeholder="Password"
                      />
                      <label for="inputPassword">Password</label>
                    </div>
                    <div v-if="errorLogin" class="alert alert-danger" role="alert">
                      Usuario o clave incorrecto.
                    </div>
                    <button
                      v-bind:class="{'disabled':loginData.disabled}"
                      v-on:click="doLogin($event)"             
                      class="btn btn-lg btn-primary btn-block btn-login font-weight-bold mb-2"
                    >Sign in</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import JQuery from "jquery";
let $ = JQuery;
window.$ = $;

export default {
  name: "Login",
  data() {
    return {
      colegios: [],
      cole: {
        nombrecolegio: "Seleccionar colegio",
        idcolegio: null
      },
      errorLogin : false,
      loginData: {
        email: '',
        password: '',
        disabled: true
      }
    };
  },
  methods : {
    selectSchool(colegio) {
      this.cole = colegio
      this.checkform()
      this.showDropdown = false
    },
    checkform(){
      if(this.loginData.email != '' && this.loginData.password != '' && this.cole != 'Seleccionar colegio'){
        console.log("todo bien!")
        this.loginData.disabled = false;
      } else {
        this.loginData.disabled = true;
        console.log('NO todo bien!')
      }
    },
    doLogin(e) {
      e.preventDefault();
      let objLogin = {
        usr: this.loginData.email,
        pwd: this.loginData.password
      }
      
      this.validateLogin(objLogin)

      if(this.loginData.disabled){
        console.log('prevent!!')
        e.preventDefault();
      }
    },
    validateLogin(login) {
      console.log('coleid: ', this.cole.idcolegio)
      console.log("objLogin ", login)
      console.log("objLogin ", login)
      console.log("usr", login.usr)
      console.log("pwd", login.pwd)

      let reqLogin = {
        idusuario: login.usr,
        idcontrasena: login.pwd,
        idcolegio: this.cole.idcolegio
      }

      axios
        .post("https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/validarusuario", reqLogin)
        .then(res => {
          console.log("res login: ", res.data);
          if(res.data.idrespuesta == 0){

            const usr = {
              user: res.data.datosusuario.nombreperfil,
              username: res.data.datosusuario.usuario,
              identity: true,
              colegio: this.cole.idcolegio
            }
            localStorage.setItem('user', JSON.stringify(usr))
            this.$router.push(`/ventas/${this.cole.idcolegio}`) 

          } else {
            this.errorLogin = true;
            return false;
          }
          
      }).catch(err => console.log(err));
    }
  },
  created() {
    axios
      .get("https://a4qwivx94l.execute-api.us-east-2.amazonaws.com/jurgens-service-prod/consultacolegio")
      .then(res => {
        console.log("res1: ", res.data);
        this.colegios = res.data.listacolegio;
        console.log("colegios1: ", this.colegios);

        console.log("colegios1: ", this.colegios[0]);
      })
      .catch(err => console.log(err));
  }
};
</script>

<style>
:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

button#dropdownMenu2 {
  text-align: left;
}

.aler {
  position: relative;
}

.dropdown-menu.show {
  min-width: 100%;
}

.login,
.image {
  min-height: 100vh;
}

a {
  color: #0abdde;
  text-decoration: none;
  background-color: transparent;
  font-size: 12px;
}

.image-drop {
  height: 15px;
}

.image-drop.right {
  float: right;
}

.bg-image {
  background-image: url("../assets/img/login_img.jpeg");
  background-size: cover;
  background-position: center;
}

.login-heading {
  font-weight: 300;
}

.btn-primary {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.btn-primary:hover {
  color: #fff;
  background-color: rgba(10, 190, 222, 0.692);
  border-color: rgba(10, 190, 222, 0.692);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0abdde;
  border-color: #0abdde;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #868686;
    border-color: #868686;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: default;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #0abdde;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(10, 190, 222, 0.692);
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  /* border-radius: 2rem; */
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label,
.form-label-group > button {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto;
  /* border-radius: 2rem; */
}

small,
.small {
  font-size: 90%;
  font-weight: 400;
}

.mb-2,
.my-2 {
  margin-bottom: 1.5rem !important;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #6c757d;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }

  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}
</style>
