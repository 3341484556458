<template>
  <div>
    <Header />
    <div class="container">
      <ul class="nav nav-tabs mb-2" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="home-tab"
            data-toggle="tab"
            href="#home"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >Mantenimiento</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="profile-tab"
            data-toggle="tab"
            href="#profile"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >Buscar alumno</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <AlumnoNuevo />
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <AlumnoMantenimiento />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import AlumnoNuevo from "../components/AlumnoNuevo";
import AlumnoMantenimiento from "../components/AlumnoMantenimiento";

export default {
  name: "Alumno",
  components: {
    Header,
    AlumnoNuevo,
    AlumnoMantenimiento
  }
};
</script>

<style scoped>

a.nav-link {
  font-size: 1.1rem;
}
</style>