<template>
  <div>
    <Header />
    <div class="container">
      <div class="row no-gutter mb-5">
        <div class="col-md-8 col-lg-8 pb-4">
          <AlumnoInfo />
          <Products />
        </div>
        <Order />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import AlumnoInfo from "../components/AlumnoInfo";
import Products from "../components/Productos";
import Order from "../components/Order";

export default {
  name: "Ventas",
  components: {
    Header,
    AlumnoInfo,
    Products,
    Order
  }
};
</script>

<style>
.btn-block {
  display: block;
  width: 100%;
}
.card-text {
  color: #fe8e2c;
}
@media (min-width: 1200px) {
  .container {
    max-width: 90% !important;
  }
}
@media (min-width: 768px) {
  .container {
      max-width: 95%;
  }
}
</style>